import React from 'react';
import { useToggle } from 'react-use';
import { Tooltip } from 'reactstrap';
import { Timecard } from '../../../../../types';

import { hasLunchOvertimeAlert, Organization, User } from '../../../../models';

export const LunchOvertimeAlert = ({
  user,
  organization,
  timecards,
}: {
  user: User;
  organization: Organization;
  timecards: Timecard[];
}) => {
  const [showTooltip, toggleShowTooltip] = useToggle(false);
  if (!hasLunchOvertimeAlert(timecards, organization.lunchTime)) return null;
  return (
    <>
      <i id={`lunch-overtime-alert-${user.id}`} className="fas fa-exclamation-triangle ml-2"></i>
      <Tooltip isOpen={showTooltip} target={`lunch-overtime-alert-${user.id}`} toggle={toggleShowTooltip}>
        {organization.lunchTime}分以上のランチ・休憩があります。朝メールで他の休みに振り替えて下さい。
      </Tooltip>
    </>
  );
};
