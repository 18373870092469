import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Card, CardBody, Table, Button, Alert } from 'reactstrap';
import qs from 'qs';
import { addMonths } from 'date-fns';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { useToggle } from 'react-use';
import { uniqBy } from 'lodash';

import Page from '../../hocs/Page';
import useCollectionSubscription from '../../hooks/useCollectionSubscription';
import useDocumentSubscription from '../../hooks/useDocumentSubscription';
import useUsersSubscription from '../../hooks/useUsersSubscription';
import useDevice from '../../hooks/useDevice';
import { db, FieldValue } from '../../../firebase';
import { monthFromSearch } from '../../../utils/calendar';
import { formatDate } from '../../../utils/time';
import MonthlyReportRow from './MonthlyReportRow';
import { Organization } from '../../../models';
import { PublicHoliday, SyncJob } from '../../../../types';

const organizationsRef = db.collection('organizations');
const syncJobsRef = db.collection('syncJobs');

export default Page(function MonthlyReport(props: any) {
  const {
    user,
    history,
    location: { search },
    organizationId,
  } = props;
  const { isMobile } = useDevice();
  const month = monthFromSearch(search);
  // TODO: v9対応まで anyで逃げる
  const organization = new Organization(
    useDocumentSubscription<Organization>(organizationsRef.doc(organizationId) as any, [organizationId]) || {}
  );
  const users = useUsersSubscription(organizationId);
  const usersRef = organizationsRef.doc(organizationId).collection('users');
  // TODO: v9対応まで anyで逃げる
  const publicHolidays = useCollectionSubscription<PublicHoliday>(
    organizationsRef.doc(organizationId).collection('publicHolidays') as any
  );
  // TODO: v9対応まで anyで逃げる
  const [syncJob] = useCollectionSubscription<SyncJob>(
    syncJobsRef.where('organizationId', '==', organizationId).orderBy('createdAt', 'desc').limit(1) as any
  );
  const canAllSync = user?.isAdmin || user?.isSysAdmin;
  const [searchValue, setSearchValue] = useState<{ label: string; value: string } | null>(null);
  const [isSearching, toggleSearching] = useToggle(false);

  const onClickChangeMonth = (diff: number) => {
    const queries = qs.parse(search.slice(1));
    history.replace({ search: `?${qs.stringify({ ...queries, month: formatDate(addMonths(month, diff), 'yyyy-MM') })}` });
  };

  const onClickResync = async () => {
    if (!user || !canAllSync || !month || !window.confirm('全体での再同期を実行します。よろしいですか？')) return;
    try {
      await syncJobsRef.add({
        from: organization.startOfOrganizationsMonth(month),
        to: organization.endOfOrganizationsMonth(month),
        target: 'all',
        status: 'initial',
        organizationId,
        createdAt: FieldValue.serverTimestamp(),
      });
      toast.success('再同期（全体）を開始しました。');
    } catch (e) {
      console.error(e);
      toast.error('再同期（全体）に失敗しました。');
    }
  };

  const isSyncing = syncJob && !['error', 'completed'].includes(syncJob.status);
  const isError = syncJob && syncJob.status === 'error';

  const filterdUsers = users.filter((_) => !searchValue || _.group === searchValue.value);

  if (user.id && organization.id && !user.isAdmin && !user.isSysAdmin && !organization.showMonthlyReport)
    return <Redirect to="/" />;

  return (
    <div className={`container-fluid position-relative ${isMobile ? '' : 'p-5'}`}>
      <Card className="my-5">
        <CardBody>
          <div className="d-flex justify-content-between py-3 align-items-baseline">
            <h3 className="d-flex align-items-end">
              <Button className="mr-1" color="primary" size="sm" onClick={() => onClickChangeMonth(-1)}>
                <i className="fas fa-chevron-left"></i>
              </Button>
              {formatDate(month, 'yyyy年M月')}
              <Button className="ml-1" color="primary" size="sm" onClick={() => onClickChangeMonth(1)}>
                <i className="fas fa-chevron-right"></i>
              </Button>
            </h3>
          </div>
          <div className="d-flex justify-content-center">
            <h4>月間集計</h4>
          </div>
          <div className="d-flex justify-content-end py-3">
            <Select
              value={searchValue}
              onChange={(option) => setSearchValue(option)}
              className={`form-select mr-1 ${isMobile ? 'w-100' : 'w-25'}`}
              options={uniqBy(
                users.filter((_) => _.group).map((_) => ({ label: _.group, value: _.group })),
                'value'
              )}
              onFocus={() => toggleSearching(true)}
              onBlur={() => toggleSearching(false)}
              isClearable
              placeholder="グループで絞り込み..."
            />
            {canAllSync && (
              <Button color="primary" className={isMobile ? 'btn-sm' : ''} onClick={onClickResync} disabled={isSyncing}>
                <i className={classnames('fas fa-sync mr-1', { 'fa-spin': isSyncing })} />{' '}
                {isSyncing ? '同期中' : '再同期（全体）'}
              </Button>
            )}
          </div>
          {isError && <Alert color="danger">再同期（全体）に失敗しました。</Alert>}
          <Table className={!isSearching ? 'sticky-table' : ''}>
            {isMobile || (
              <thead>
                <tr>
                  <th>No.</th>
                  <th>名前</th>
                  <th>勤務率</th>
                  <th>就業形態</th>
                  <th>勤務</th>
                  <th>出勤日数</th>
                  <th>休日出勤数</th>
                  <th>法定休日出勤</th>
                  {!organization.excessStatutoryHolidayIsWork && <th>所定休日出勤</th>}
                  <th>残業</th>
                  <th>深夜勤務</th>
                  <th>
                    代休
                    <br />
                    (消化/付与)
                  </th>
                  <th>
                    有休(時間)
                    <br />
                    (消化/付与)
                  </th>
                  <th>
                    有休(日)
                    <br />
                    (消化/付与)
                  </th>
                  <th>
                    特別休暇
                    <br />
                    (消化/付与)
                  </th>
                  <th>遅刻/早退</th>
                  <th>在宅勤務</th>
                </tr>
              </thead>
            )}
            <tbody>
              {filterdUsers.map((user) => (
                <MonthlyReportRow
                  key={user.id}
                  user={user}
                  userRef={usersRef.doc(user.id)}
                  search={search}
                  month={month}
                  publicHolidays={publicHolidays}
                  organization={organization}
                  isMobile={isMobile}
                />
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
});
