import React from 'react';
import { useToggle } from 'react-use';
import { Card, CardBody, Badge, Button, Table } from 'reactstrap';
import classnames from 'classnames';
import { format, differenceInSeconds, addSeconds, isSameDay, isEqual } from 'date-fns';
import { groupBy, mapValues, isEmpty, omit } from 'lodash';
import { DocumentReference, Timestamp } from 'firebase/firestore';

import { getOvertime, getLateNight, getAnotherOvertime } from '../../../utils/overtime';
import { getCalendar } from '../../../utils/calendar';
import { formatDate } from '../../../utils/time';
import useDevice from '../../hooks/useDevice';
import useTimetableRowHeight from '../../hooks/useTimetableRowHeight';
import TransferHolidayModal from '../../modals/TransferHolidayModal';
import { WorkHourIntervalAlert } from './alerts/WorkHourIntervalAlert';
import { User, Leave, Organization, WorkStyle, Shift } from '../../../models';
import { Timecard, AssignedHoliday, PublicHoliday, TransferHoliday } from '../../../../types';

type Props = {
  user: User;
  userRef: DocumentReference;
  month: Date;
  timecards: Timecard[];
  assignedHolidays: AssignedHoliday[];
  publicHolidays: PublicHoliday[];
  transferHolidays: TransferHoliday[];
  leaves: Leave[];
  organization: Organization;
  workStyle?: WorkStyle;
  shifts: Shift[];
};

type TimetableRow = {
  date: Date;
  type: string;
  work: number;
} & { [key: string]: number };

const timetableItems = [
  {
    label: '',
    type: 'transfer-holiday',
  },
  {
    label: '勤務',
    type: 'work',
  },
  {
    label: '休憩',
    type: 'lunch',
  },
  {
    label: '休日出勤',
    type: 'holiday-work',
  },
  {
    label: '残業',
    type: 'overtime',
  },
  {
    label: '深夜勤務',
    type: 'late-night',
  },
  {
    label: '代休',
    type: 'compensatory-holiday',
  },
  {
    label: '有休',
    type: 'paid-holiday',
  },
  {
    label: '特別休暇',
    type: 'special-holiday',
  },
  {
    label: '遅刻/早退',
    type: 'late-or-early',
  },
];

const getSeconds = (array: { from: Timestamp; to: Timestamp; lunchTime?: number }[]) =>
  array
    .map(({ from, to, lunchTime }) => differenceInSeconds(to.toDate(), from.toDate()) - (lunchTime || 0) * 60)
    .reduce((x, y) => x + y, 0);

export default function Timetable(props: Props) {
  const {
    user,
    month,
    timecards,
    assignedHolidays,
    publicHolidays,
    transferHolidays,
    leaves,
    organization,
    workStyle,
    shifts,
  } = props;
  const { isMobile } = useDevice();
  const rowHeight = useTimetableRowHeight();
  const startDay = organization.startOfOrganizationsMonth(month);
  const timetable = getCalendar({ start: startDay, publicHolidays, transferHolidays, shifts }).map(({ date, type }) => {
    const timecardsByDate = timecards.filter(({ from }) => isSameDay(date, from.toDate()));
    const assignedHolidaysByDate = assignedHolidays.filter((_) => isSameDay(date, _.date.toDate()));
    const groupedAssignedHolidays = mapValues(groupBy(assignedHolidaysByDate, 'type'), getSeconds);
    const lunch = timecards.filter(({ from, type }) => isSameDay(date, from.toDate()) && type === 'lunch');
    const addLunchTime = assignedHolidaysByDate.reduce((x, y) => x + (y.lunchTime || 0), 0) * 60;
    const prescribedWorkTime = workStyle?.usePersonalTimeSetting
      ? workStyle.prescribedWorkTime(startDay, publicHolidays, leaves, shifts, user.workPerWeek)
      : organization.prescribedWorkTime(startDay, publicHolidays, leaves, shifts, user.workPerWeek);
    const overtime = organization.isViewAnotherOvertime
      ? getAnotherOvertime(date, organization, timecards, workStyle)
      : getOvertime(startDay, date, organization, timecards, workStyle, prescribedWorkTime);
    const lateNight = getLateNight(date, organization, timecards);
    return {
      date,
      type,
      work: getSeconds(timecardsByDate.filter((_) => _.type === 'work')),
      ...groupedAssignedHolidays,
      ...(getSeconds(lunch) + addLunchTime ? { lunch: getSeconds(lunch) + addLunchTime } : {}),
      ...(overtime ? { overtime: overtime * 60 } : {}),
      ...(lateNight ? { 'late-night': lateNight * 60 } : {}),
    } as TimetableRow;
  });

  const toHHmm = (seconds: number) => seconds && format(addSeconds(startDay, seconds), 'H:mm');

  return (
    <Card>
      <CardBody>
        {isMobile ? (
          <div className="d-flex justify-content-around">
            <Table>
              <tbody>
                {timetable.map(({ date, work, type: dateType, ...values }) => {
                  const transferHoliday = transferHolidays.find(({ to }) => isEqual(to.toDate(), date));
                  const mobileBadge = (label: string, type: string) => (
                    <Badge className={classnames('mx-2', type)} style={{ width: 64 }}>
                      <small>
                        {type === 'overtime' && organization.isViewAnotherOvertime ? <b>超過時間</b> : <b>{label}</b>}
                      </small>
                    </Badge>
                  );
                  const isHoliday = organization.excessStatutoryHolidayIsWork
                    ? dateType === 'legal-holiday'
                    : dateType.includes('holiday');
                  return (
                    <tr key={date.toISOString()}>
                      <td>
                        <span className={dateType}>{formatDate(date, 'yyyy/MM/dd(E)')}</span>
                      </td>
                      <td>
                        {timetableItems.map(({ label, type }) => (
                          <div key={`${date}${label}`}>
                            {type === 'work' && work !== 0 && (
                              <>
                                {mobileBadge(label, type)}
                                {toHHmm(work)}
                                <WorkHourIntervalAlert {...props} date={date} />
                              </>
                            )}
                            {type === 'paid-holiday' &&
                              (values[`paid-holiday-date`] != null || values[`paid-holiday-time`] != null) && (
                                <>
                                  {mobileBadge(label, type)}
                                  {(values[`paid-holiday-date`] && values[`lunch`] && '半休') ||
                                    (values[`paid-holiday-date`] && '終日') ||
                                    toHHmm(values[`paid-holiday-time`])}
                                </>
                              )}
                            {values[type] && (
                              <>
                                {mobileBadge(label, type)}
                                {['refresh-holiday', 'special-holiday', 'compensatory-holiday'].includes(type) &&
                                  values[type] &&
                                  '終日'}
                                {type === 'holiday-work' && isHoliday && values[type] && toHHmm(work)}
                                {[
                                  'paid-holiday',
                                  'refresh-holiday',
                                  'special-holiday',
                                  'compensatory-holiday',
                                  'holiday-work',
                                ].includes(type) || toHHmm(values[type])}
                              </>
                            )}
                          </div>
                        ))}
                      </td>
                      <td>
                        <TransferHolidayColumn {...props} date={date} isHoliday={isHoliday} transferHoliday={transferHoliday} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        ) : (
          <div className="d-flex justify-content-around">
            {timetableItems.map(({ label, type }, i) => (
              <div className="d-flex flex-column align-items-center w-100" key={i}>
                <div className="timetable-header pr-1">
                  {label && (
                    <Badge className={classnames('w-100', 'py-2', type)}>
                      <small>
                        {type === 'overtime' && organization.isViewAnotherOvertime ? <b>超過時間</b> : <b>{label}</b>}
                      </small>
                    </Badge>
                  )}
                </div>
                {timetable.map(({ date, work, type: dateType, ...values }, i) => {
                  const isEmptyRow = isEmpty(omit(values, 'date')) && !work;
                  const rowStyle = isEmptyRow
                    ? { height: rowHeight.empty, lineHeight: rowHeight.empty }
                    : { height: rowHeight.normal, lineHeight: rowHeight.normal };
                  const isHoliday = organization.excessStatutoryHolidayIsWork
                    ? dateType === 'legal-holiday'
                    : dateType.includes('holiday');

                  if (type === 'transfer-holiday') {
                    const transferHoliday = transferHolidays.find(({ to }) => isEqual(to.toDate(), date));
                    return (
                      <div key={i} className={isEmptyRow ? 'timetable-empty-row' : 'timetable-row'} style={rowStyle}>
                        <TransferHolidayColumn {...props} date={date} isHoliday={isHoliday} transferHoliday={transferHoliday} />
                      </div>
                    );
                  }

                  if (isEmptyRow) {
                    return <div key={i} className="timetable-empty-row" style={rowStyle}></div>;
                  }

                  if (type === 'work' && work)
                    return (
                      <div key={i} className="timetable-row" style={rowStyle}>
                        {toHHmm(work)}
                        <WorkHourIntervalAlert {...props} date={date} />
                      </div>
                    );
                  if (type === 'paid-holiday') {
                    return (
                      <div key={i} className="timetable-row" style={rowStyle}>
                        {(values[`paid-holiday-date`] && values[`lunch`] && '半休') ||
                          (values[`paid-holiday-date`] && '終日') ||
                          toHHmm(values[`paid-holiday-time`])}
                      </div>
                    );
                  }
                  if (['refresh-holiday', 'special-holiday', 'compensatory-holiday'].includes(type)) {
                    return (
                      <div key={i} className="timetable-row" style={rowStyle}>
                        {values[type] && '終日'}
                      </div>
                    );
                  }
                  if (type === 'holiday-work' && isHoliday) {
                    return (
                      <div key={i} className="timetable-row" style={rowStyle}>
                        {toHHmm(work)}
                      </div>
                    );
                  }
                  return (
                    <div key={i} className="timetable-row" style={rowStyle}>
                      {toHHmm(values[type])}
                    </div>
                  );
                })}
                <div className="timetable-footer pr-1">
                  {label && (
                    <Badge className={classnames('w-100', 'py-2', type)}>
                      <small>
                        {type === 'overtime' && organization.isViewAnotherOvertime ? <b>超過時間</b> : <b>{label}</b>}
                      </small>
                    </Badge>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </CardBody>
    </Card>
  );
}

const TransferHolidayColumn = (props: { date: Date; isHoliday: boolean; transferHoliday: TransferHoliday | undefined }) => {
  const { isHoliday, transferHoliday } = props;
  const [show, toggle] = useToggle(false);
  return (
    <>
      {(isHoliday || transferHoliday != null) && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '2px' }}>
          {transferHoliday != null ? (
            <Button outline size="sm" color="warning" onClick={toggle} style={{ padding: '2px 4px' }}>
              解除
            </Button>
          ) : (
            <Button outline size="sm" color="primary" onClick={toggle} style={{ padding: '2px 4px' }}>
              振替
            </Button>
          )}
          <TransferHolidayModal {...props} isOpen={show} onClickClose={toggle} />
        </div>
      )}
    </>
  );
};
