import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/auth';
import 'firebase/compat/functions';

import env from './env';

const config = {
  apiKey: env('FIREBASE_API_KEY'),
  authDomain: env('FIREBASE_AUTH_DOMAIN'),
  databaseURL: env('FIREBASE_DATABASE_URL'),
  projectId: env('FIREBASE_PROJECT_ID'),
  storageBucket: env('FIREBASE_STORAGE_BUCKET'),
  messagingSenderId: env('FIREBASE_MESSAGING_SENDER_ID'),
};

firebase.initializeApp(config);

export const auth = firebase.auth();
if (env('AUTH_EMULATOR') === 'true') {
  auth.useEmulator('http://localhost:9099/');
}

export const functions = firebase.app().functions('asia-northeast1');
if (env('CLOUD_FUNCTIONS_EMULATOR') === 'true') {
  functions.useEmulator('localhost', 5001);
}

export const db = firebase.firestore();
if (env('FIRESTORE_EMULATOR') === 'true') {
  db.settings({
    host: "localhost:8080",
    ssl: false,
    experimentalForceLongPolling: true
  });
}

export const EmailAuthProvider = firebase.auth.EmailAuthProvider;
export const Timestamp = firebase.firestore.Timestamp;
export const FieldValue = firebase.firestore.FieldValue;
