import React, { useReducer, createContext, Dispatch, ReactNode } from 'react';

const initialState = {
  isSyncing: false,
};

type State = { isSyncing: boolean };
type Action = { type: 'beginSync' | 'endSync' };
type ContextInterface = {
  state: State;
  dispatch: Dispatch<Action>;
};

const SyncContext = createContext<ContextInterface>({
  state: { isSyncing: false },
  dispatch: () => {},
});

const reducer = (state: State, action: Action) => {
  if (action.type === 'beginSync') {
    return { ...state, isSyncing: true };
  }
  if (action.type === 'endSync') {
    return { ...state, isSyncing: false };
  }
  throw new Error(`invalid action ${action.type}`);
};

const SyncProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <SyncContext.Provider value={{ state, dispatch }}>{children}</SyncContext.Provider>;
};

export { SyncContext, SyncProvider };
