import { isSameDay, min, max, differenceInMinutes } from 'date-fns';
import { totalWorkTime } from './calendar';
import { formatDate, parseDate } from './time';

export const getOvertime = (startDay, date, organization, timecards, workStyle, prescribedWorkTime) => {
  const { isFlextime } = organization;
  const { usePersonalTimeSetting, usePersonalFlexTime, usePersonalWorkTime } = workStyle || {};
  const totalWorkTimeByDate = totalWorkTime(
    startDay,
    organization,
    timecards.filter((_) => isSameDay(date, _.date.toDate()) && _.type === 'work')
  );
  const totalWorkTimeByMonth = totalWorkTime(
    startDay,
    organization,
    timecards.filter((_) => date >= _.date.toDate() && _.type === 'work')
  );

  const useFlexTime = usePersonalTimeSetting ? usePersonalFlexTime : isFlextime;
  if (useFlexTime) {
    const diff = totalWorkTimeByMonth - prescribedWorkTime;
    if (diff > 0) {
      const currentTotalWorkTime = totalWorkTimeByDate;
      return diff > currentTotalWorkTime ? currentTotalWorkTime : diff;
    }
    return 0;
  }

  const workTimeBeginByDate = usePersonalWorkTime
    ? workStyle.workTimeBeginByDate(date)
    : organization.workTimeBeginByDate(date);
  const workTimeEndByDate = usePersonalWorkTime ? workStyle.workTimeEndByDate(date) : organization.workTimeEndByDate(date);
  const currentTimecards = timecards.filter((_) => isSameDay(date, _.date.toDate()) && _.type === 'work');
  const beforeStartOvertime = currentTimecards
    .filter((_) => _.from.toDate() < workTimeBeginByDate)
    .map(({ to, from }) => differenceInMinutes(min([to.toDate(), workTimeBeginByDate]), from.toDate()))
    .reduce((x, y) => x + y, 0);
  const afterEndOvertime = currentTimecards
    .filter((_) => _.to.toDate() > workTimeEndByDate)
    .map(({ to, from }) => differenceInMinutes(to.toDate(), max([from.toDate(), workTimeEndByDate])))
    .reduce((x, y) => x + y, 0);
  return beforeStartOvertime + afterEndOvertime;
};

const lateNightBegin = (date) => parseDate(`${formatDate(date, 'yyyy-MM-dd')} 22:00`);
const lateNightEnd = (date) => parseDate(`${formatDate(date, 'yyyy-MM-dd')} 05:00`);

export const getLateNight = (date, organization, timecards) => {
  const currentTimecards = timecards.filter((_) => isSameDay(date, _.date.toDate()) && _.type === 'work');
  const beforeStartLateNight = currentTimecards
    .filter((_) => _.from.toDate() < lateNightEnd(date))
    .map(({ to, from }) => differenceInMinutes(min([to.toDate(), lateNightEnd(date)]), from.toDate()))
    .reduce((x, y) => x + y, 0);
  const afterEndLateNight = currentTimecards
    .filter((_) => _.to.toDate() > lateNightBegin(date))
    .map(({ to, from }) => differenceInMinutes(to.toDate(), max([from.toDate(), lateNightBegin(date)])))
    .reduce((x, y) => x + y, 0);
  return beforeStartLateNight + afterEndLateNight;
};

export const getAnotherOvertime = (date, organization, timecards, workStyle) => {
  const { usePersonalWorkTime, usePersonalFlexTime, workTimeMinutesPerDay } = workStyle || {};
  const currentTimecards = timecards.filter((_) => isSameDay(date, _.date.toDate()) && _.type === 'work');

  if (usePersonalWorkTime) {
    const workTimeBeginByDate = workStyle.workTimeBeginByDate(date);
    const workTimeEndByDate = workStyle.workTimeEndByDate(date);
    const beforeStartOvertime = currentTimecards
      .filter((_) => _.from.toDate() < workTimeBeginByDate)
      .map(({ to, from }) => differenceInMinutes(min([to.toDate(), workTimeBeginByDate]), from.toDate()))
      .reduce((x, y) => x + y, 0);
    const afterEndOvertime = currentTimecards
      .filter((_) => _.to.toDate() > workTimeEndByDate)
      .map(({ to, from }) => differenceInMinutes(to.toDate(), max([from.toDate(), workTimeEndByDate])))
      .reduce((x, y) => x + y, 0);
    return beforeStartOvertime + afterEndOvertime;
  } else {
    const currentWorkTimeByDate = currentTimecards
      .map(({ to, from }) => differenceInMinutes(to.toDate(), from.toDate()))
      .reduce((x, y) => x + y, 0);
    const diff = currentWorkTimeByDate - (usePersonalFlexTime ? workTimeMinutesPerDay : organization.workTimeMinutesPerDay);
    return diff > 0 ? diff : 0;
  }
};
