import React from 'react';
import { Card, CardBody, Button, Table } from 'reactstrap';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';

import useCollectionSubscription from '../../../hooks/useCollectionSubscription';
import { db } from '../../../../firebase';

const invitationsRef = db.collection('invitations');

export default function Invitations(props) {
  const invitations = useCollectionSubscription(invitationsRef, []);

  const url = (token, organization) => {
    if (!organization) return;

    const { origin } = window.location;
    return `${origin}/signup?token=${token}&organizationId=${organization.id}`;
  };

  const onClickCopy = (token, organization) => {
    copy(url(token, organization));
    toast.success('URLをクリップボードにコピーしました');
  };

  const onClickDelete = (token) => {
    invitationsRef.doc(token).delete();
    toast.warn('削除しました');
  };

  const invitationMailResult = result => {
    if (!result) return '未送信';
    if (result === 'ok') return '送信済み';
    return <span title={result}>送信エラー</span>
  }

  return (
    <div>
      <Card>
        <CardBody>
          <Table>
            <thead>
              <tr>
                <th>組織名</th>
                <th>メールアドレス</th>
                <th>招待メール</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {invitations.map(({ email, token, organization, result }) => (
                <tr key={token}>
                  <td className="text-nowrap">{organization && organization.name}</td>
                  <td className="text-nowrap">{email}</td>
                  <td className="text-nowrap">{invitationMailResult(result)}</td>
                  <td className="text-right text-nowrap">
                    <Button color="info" onClick={onClickCopy.bind(this, token, organization)}>
                      <i className="fas fa-copy mr-1"></i>招待URLコピー
                    </Button>
                    <Button color="danger" className="ml-2" onClick={onClickDelete.bind(this, token)}>
                      <i className="fas fa-trash mr-1"></i>削除
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
}
