import React from 'react';
import { useToggle } from 'react-use';
import { Tooltip } from 'reactstrap';
import { format } from 'date-fns';

export const WorkHourIntervalAlert = (props) => {
  const { timecards, date, organization } = props;
  const [showTooltip, toggleShowTooltip] = useToggle(false);

  return (
    organization &&
    organization.hasWorkHourIntervalAlert(timecards, date) && (
      <>
        <i id={`holiday-alert-${format(date, 'yyyy-MM-dd')}`} className="fas fa-exclamation-triangle ml-1"></i>
        <Tooltip isOpen={showTooltip} target={`holiday-alert-${format(date, 'yyyy-MM-dd')}`} toggle={toggleShowTooltip}>
          勤務間インターバル（{organization.WorkHourIntervalAlert}h）を超過しています
        </Tooltip>
      </>
    )
  );
};
