import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input, Card, CardBody, CardFooter, Alert } from 'reactstrap';
import { toast } from 'react-toastify';

import { db, auth } from '../../firebase';
import Page from '../hocs/Page';

const usersRef = db.collection('users');

const ERRORS = {
  'auth/requires-recent-login': '最終ログインから時間が経過しています。再ログイン後にもう一度操作下さい。',
};

export default Page(function Profile(props) {
  const { user, history } = props;
  const [email, setEmail] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [errorMessage, setErrorMessage] = useState();

  const isPasswordValid = !!password && password.length >= 6;
  const isPasswordConfimationValid = !!passwordConfirmation && password === passwordConfirmation;
  const isUnsubmittable =
    !user || !displayName || !email || (!!password && (!isPasswordValid || !isPasswordConfimationValid));

  useEffect(() => {
    if (user) {
      setEmail(user.email);
      setDisplayName(user.displayName);
    }
  }, [user]);

  const onSubmit = async (event) => {
    event.preventDefault();
    if (isUnsubmittable) return;
    try {
      await Promise.all([
        // プロファイルの表示名更新
        auth.currentUser.updateProfile({ displayName }),
        // ユーザー登録
        usersRef.doc(user.id).set({ displayName }, { merge: true }),
      ]);
      if (password) {
        await auth.currentUser.updatePassword(password);
      }
      toast.success('更新しました。');
      history.push('/');
    } catch (error) {
      console.error(error);
      const message = ERRORS[error.code] || '不明なエラーです。';
      setErrorMessage(message);
    }
  };

  return (
    <div className="container py-5 position-relative">
      <Card className="my-5">
        <Form onSubmit={onSubmit}>
          <CardBody>
            <FormGroup>
              <Label for="email">メールアドレス</Label>
              <Input type="email" id="email" value={email} disabled />
            </FormGroup>
            <FormGroup>
              <Label for="displayName">表示名</Label>
              <Input
                type="text"
                id="displayName"
                onChange={(e) => setDisplayName(e.target.value)}
                valid={!!displayName}
                value={displayName}
              />
            </FormGroup>
            <FormGroup>
              <Label for="password">パスワード</Label>
              <Input
                type="password"
                id="password"
                onChange={(e) => setPassword(e.target.value)}
                valid={isPasswordValid}
                invalid={password ? !isPasswordValid : false}
              />
            </FormGroup>
            <FormGroup>
              <Label for="password_confirmation">パスワード（確認）</Label>
              <Input
                type="password"
                id="password_confirmation"
                onChange={(e) => setPasswordConfirmation(e.target.value)}
                valid={isPasswordConfimationValid}
                invalid={passwordConfirmation ? !isPasswordConfimationValid : false}
              />
            </FormGroup>
            {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
          </CardBody>
          <CardFooter>
            <Button color="primary" type="submit" onClick={onSubmit} disabled={isUnsubmittable}>
              更新
            </Button>
          </CardFooter>
        </Form>
      </Card>
    </div>
  );
});
