import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Input,
  Label,
  Alert,
  Card,
  CardHeader,
  CardBody,
  Table,
  Form,
  FormGroup,
  FormFeedback,
} from 'reactstrap';
import ToggleButton from 'react-toggle-button';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { formatDate, startOfDay } from '../../utils/time';

import useCollectionSubscription from '../hooks/useCollectionSubscription';
import { db, auth, functions } from '../../firebase';
import { WorkStyle } from '../../models';

const usersRef = db.collection('users');
const updateEmail = functions.httpsCallable('updateEmail');

export default function UserSettingModal(props) {
  const {
    isOpen,
    onClickClose,
    firebaseUser: { uid },
    user,
  } = props;
  const [displayName, setDisplayName] = useState('');
  const [email, setEmail] = useState('');
  const [employeeNumber, setEmployeeNumber] = useState('');
  const [group, setGroup] = useState('');

  useEffect(() => {
    setDisplayName(user.displayName);
    setEmail(user.email);
    setEmployeeNumber(user.employeeNumber || '');
    setGroup(user.group);
  }, [isOpen, user]);

  const onChangeEmployeeNumber = async ({ target: { value } }) => {
    user.ref.set({ employeeNumber: Number(value) }, { merge: true });
  };

  const onChangeDisplayName = async () => {
    if (user.displayName === displayName) return;
    if (!window.confirm(`表示名を${displayName}に変更します。よろしいですか？`)) return;
    try {
      await Promise.all([
        // プロファイルの表示名更新
        auth.currentUser.updateProfile({ displayName }),
        // ユーザー登録
        usersRef.doc(user.id).set({ displayName }, { merge: true }),
      ]);
      toast.success('表示名を変更しました。');
    } catch (e) {
      console.error(e);
      toast.error('表示名の変更に失敗しました。');
    }
  };

  const onChangeEmail = async () => {
    if (user.email === email) return;
    if (!window.confirm(`メールアドレスを${email}に変更します。よろしいですか？`)) return;
    try {
      await updateEmail({ id: user.id, email });
      toast.success('メールアドレスを変更しました。');
    } catch (e) {
      console.error(e);
      toast.error('メールアドレスの変更に失敗しました。');
    }
  };

  const onToggleAdmin = async (value) => {
    await usersRef.doc(user.id).set({ role: value ? 'member' : 'admin' }, { merge: true });
    toast.success('権限を変更しました。');
  };

  const onToggleShift = async (value) => {
    await usersRef.doc(user.id).set({ useShift: !value }, { merge: true });
    toast.success('シフト設定を変更しました。');
  };

  const onChangeWorkPerWeek = async ({ target: { value } }) => {
    await usersRef.doc(user.id).set({ workPerWeek: value }, { merge: true });
  };

  const onBlurGroup = async () => {
    await usersRef.doc(user.id).set({ group }, { merge: true });
  }

  return (
    <Modal isOpen={isOpen} toggle={onClickClose} size="lg">
      <ModalHeader>{user.displayName}の設定</ModalHeader>
      <ModalBody>
        <div>
          <Label for="employee-number">社員番号</Label>
          <Input
            type="number"
            name="employee-number"
            id="employee-number"
            value={employeeNumber}
            onChange={onChangeEmployeeNumber}
          />
        </div>
        <div>
          <Label for="display-name">表示名の変更</Label>
          <span className="d-flex">
            <Input name="display-name" id="display-name" value={displayName} onChange={(e) => setDisplayName(e.target.value)} />
            <Button className="ml-1 w-25" color="primary" onClick={onChangeDisplayName}>
              変更
            </Button>
          </span>
        </div>
        <div>
          <Label for="email">メールアドレスの変更</Label>
          <span className="d-flex">
            <Input
              name="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="aaaaa@example.com"
            />
            <Button className="ml-1 w-25" color="primary" onClick={onChangeEmail}>
              変更
            </Button>
          </span>
        </div>
        <div>
          <Label for="admin">管理者権限</Label>
          {user.id === uid ? (
            <Alert color="danger">自分の権限は変更できません</Alert>
          ) : (
            <ToggleButton inactiveLabel="" activeLabel="○" value={user.role === 'admin'} onToggle={onToggleAdmin} />
          )}
        </div>
        <div>
          <Label for="userShift">シフト制</Label>
          <ToggleButton inactiveLabel="" activeLabel="○" value={user.useShift} onToggle={onToggleShift} />
        </div>
        <div>
          <Label for="workPerWeek">勤務割合（週間）</Label>
          <small>例）週5日に対して4日の勤務形態であれば0.8を設定します。</small>
          <Input
            id="workPerWeek"
            type="number"
            onChange={onChangeWorkPerWeek}
            value={user.workPerWeek}
            max="1.0"
            min="0.1"
            step="0.1"
          />
        </div>
        <div>
          <Label for="group">グループ</Label>
          <Input
            id="group"
            onChange={(e) => setGroup(e.target.value)}
            onBlur={onBlurGroup}
            value={group}
          />
        </div>
        <WorkStyleSetting {...props} />
      </ModalBody>
      <ModalFooter>
        <Button className="cancel" color="secondary" onClick={onClickClose}>
          閉じる
        </Button>
      </ModalFooter>
    </Modal>
  );
}

function WorkStyleSetting(props) {
  const { organizationId, user } = props;
  const workStylesRef = organizationId && user && db.collection(`organizations/${organizationId}/users/${user.id}/workStyles`);
  const workStyles = useCollectionSubscription(workStylesRef.orderBy('from'), [organizationId, user]).map(
    (_) => new WorkStyle(_)
  );
  const { register, handleSubmit, errors, watch } = useForm({ mode: 'onBlur' });
  const usePersonalWorkTime = watch('use_personal_work_time', false);
  const usePersonalFlexTime = watch('use_personal_flex_time', false);
  const isUnsubmittable = Boolean(Object.values(errors).length) || (usePersonalWorkTime && usePersonalFlexTime);

  const onSubmit = async (values) => {
    const {
      work_style_from: from,
      work_style_to: to,
      work_time_per_day: workTimePerDay = 0,
      work_time_begin: workTimeBegin = '',
      work_time_end: workTimeEnd = '',
    } = values;
    try {
      await workStylesRef.add({
        from: startOfDay(from),
        to: startOfDay(to),
        workTimeBegin,
        workTimeEnd,
        workTimePerDay,
        usePersonalWorkTime,
        usePersonalFlexTime,
      });
      toast.success('登録しました');
    } catch (e) {
      console.error(e);
      toast.error('登録に失敗しました');
    }
  };

  const WorkStyleRow = (workStyle) => {
    const { id, ref, from, to } = workStyle;
    const onDelete = async () => {
      if (!window.confirm('削除しますか？') || !ref) return;
      return ref.delete();
    };
    return (
      <tr key={id}>
        <td>{formatDate(from.toDate(), 'yyyy-MM-dd')}</td>
        <td>{formatDate(to.toDate(), 'yyyy-MM-dd')}</td>
        <td>{workStyle?.workStyleText}</td>
        <td>
          <Button color="danger" size="sm" onClick={onDelete}>
            <i className="fas fa-trash" />
          </Button>
        </td>
      </tr>
    );
  };

  return (
    <Card className="my-5">
      <CardHeader>就業形態</CardHeader>
      <CardBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-2">
            <Label>適用期間</Label>
            <FormGroup className="d-flex align-items-center">
              <Input
                className="mr-1"
                type="date"
                name="work_style_from"
                innerRef={register({ required: '開始日が未入力です', valueAsDate: true })}
              />
              　〜　
              <Input
                className="mr-1"
                type="date"
                name="work_style_to"
                innerRef={register({ required: '終了日が未入力です', valueAsDate: true })}
              />
            </FormGroup>
            <FormGroup check className="mb-2">
              <Label check>
                <Input name="use_personal_work_time" type="checkbox" innerRef={register} />
                個人別の就業時間を設定する
              </Label>
            </FormGroup>
          </div>

          {usePersonalWorkTime && (
            <FormGroup>
              <Label>始業/終業時刻</Label>
              <span className="d-flex align-items-center">
                <div className="mr-1" style={{ width: '100%' }}>
                  <Input
                    name="work_time_begin"
                    type="time"
                    valid={!Boolean(errors.work_time_begin)}
                    invalid={Boolean(errors.work_time_begin)}
                    innerRef={register({ required: '始業時刻が未入力です' })}
                  />
                  {errors.work_time_begin && <FormFeedback>{errors.work_time_begin.message}</FormFeedback>}
                </div>
                　〜　
                <div style={{ width: '100%' }}>
                  <Input
                    name="work_time_end"
                    className="mr-1"
                    type="time"
                    valid={!Boolean(errors.work_time_end)}
                    invalid={Boolean(errors.work_time_end)}
                    innerRef={register({ required: '終業時刻が未入力です' })}
                  />
                  {errors.work_time_end && <FormFeedback>{errors.work_time_end.message}</FormFeedback>}
                </div>
              </span>
            </FormGroup>
          )}

          <FormGroup check className="mb-2">
            <Label check>
              <Input name="use_personal_flex_time" type="checkbox" innerRef={register} />
              個人別のフレックスタイム制を設定する
            </Label>
          </FormGroup>

          {usePersonalFlexTime && (
            <FormGroup>
              <Label>勤務時間/日</Label>
              <Input
                name="work_time_per_day"
                type="number"
                valid={!Boolean(errors.work_time_per_day)}
                invalid={Boolean(errors.work_time_per_day)}
                max="24"
                min="0"
                innerRef={register({
                  required: '勤務時間/日が未入力です',
                  max: { value: 24, message: '24以下で指定して下さい' },
                  min: { value: 0, message: '0以上で指定して下さい' },
                  valueAsNumber: true,
                })}
              />
              {errors.work_time_per_day && <FormFeedback>{errors.work_time_per_day.message}</FormFeedback>}
            </FormGroup>
          )}

          <Button
            className="ml-auto d-block"
            color="primary"
            disabled={isUnsubmittable}
            type="submit"
            onClick={handleSubmit(onSubmit)}
          >
            <i className="fas fa-edit mr-1"></i>登録
          </Button>
        </Form>
      </CardBody>
      <CardBody>
        <Table>
          <thead>
            <tr>
              <th>開始</th>
              <th>終了</th>
              <th>就業形態</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{workStyles.map((workStyle) => WorkStyleRow(workStyle))}</tbody>
        </Table>
      </CardBody>
    </Card>
  );
}
