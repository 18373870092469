import qs from 'qs';
import { startOfMonth, isEqual, parseISO, addMonths, subDays } from 'date-fns';
import { formatDate } from '../utils/time';
import { isSunday, isSaturday, eachDay } from '../shared/utils/date';
import { isHoliday } from '@holiday-jp/holiday_jp';

const isCompanyHoliday = (publicHolidays, date) => publicHolidays.find((_) => isEqual(_.date.toDate(), date));
const isLegalHoliday = (date) => isSunday(date);
const isExcessStatutoryHoliday = (date, publicHolidays) =>
  isSaturday(date) || isHoliday(formatDate(date, 'yyyy-MM-dd')) || isCompanyHoliday(publicHolidays, date);

export const getCalendar = ({ start, publicHolidays = [], transferHolidays = [], shifts = [] }) => {
  return eachDay(start, subDays(addMonths(start, 1), 1)).map((date) => {
    const transfer = transferHolidays.find(
      ({ from, to }) => isEqual(date, from.toDate()) || isEqual(date, to.toDate(date, to.toDate()))
    );
    const shift = shifts.find((_) => isEqual(date, _.date.toDate()));
    if (transfer) {
      if (isEqual(date, transfer.to.toDate())) {
        if (isLegalHoliday(transfer.from.toDate())) {
          return { date, type: 'legal-holiday' };
        } else {
          return { date, type: 'regular-holiday' };
        }
      } else {
        return { date, type: 'weekday' };
      }
    }

    if (shift) {
      return { date, type: shift.type === 'holiday' ? 'legal-holiday' : 'weekday' };
    }

    if (isLegalHoliday(date)) {
      return { date, type: 'legal-holiday' };
    } else if (isExcessStatutoryHoliday(date, publicHolidays)) {
      return { date, type: 'regular-holiday' };
    } else {
      return { date, type: 'weekday' };
    }
  });
};

export const getWeekdays = (dateRange, publicHolidays) => {
  return dateRange.filter(
    (date) =>
      !isSunday(date) &&
      !isSaturday(date) &&
      !isHoliday(formatDate(date, 'yyyy-MM-dd')) &&
      !isCompanyHoliday(publicHolidays, date)
  );
};

export const monthFromSearch = (search) => {
  const { month } = qs.parse(search.slice(1));
  return (month && parseISO(month)) || startOfMonth(new Date());
};

export const totalWorkTime = (startDay, organization, timecards, assignedHolidays = []) => {
  const workTime = timecards
    .filter((_) => _.type === 'work')
    .map((_) => (_.to.seconds - _.from.seconds) / 60)
    .reduce((x, y) => x + y, 0);
  // 時間休暇は実際の時間分を加算
  const hoursHolidayTime = assignedHolidays
    .filter(
      (_) =>
        _.date.toDate() >= startDay &&
        _.date.toDate() < subDays(addMonths(startDay, 1), 1) &&
        ['paid-holiday-time'].includes(_.type)
    )
    .map((_) => (_.to.seconds - _.from.seconds) / 60)
    .reduce((x, y) => x + y, 0);
  // 終日休暇は1日の基準労働時間分を加算
  const datesHolidayTime = assignedHolidays
    .filter(
      (_) =>
        _.date.toDate() >= startDay &&
        _.date.toDate() < subDays(addMonths(startDay, 1), 1) &&
        ['compensatory-holiday', 'paid-holiday-date'].includes(_.type)
    )
    .map(() => organization.workTimeMinutesPerDay)
    .reduce((x, y) => x + y, 0);
  return workTime + hoursHolidayTime + datesHolidayTime;
};

export const actualWorkTime = (timecards) =>
  timecards
    .filter((_) => _.type === 'work')
    .map((_) => (_.to.seconds - _.from.seconds) / 60)
    .reduce((x, y) => x + y, 0);

export const holidayWorkTime = (calendar, timecards, excessStatutoryHolidayIsWork = false) =>
  excessStatutoryHolidayIsWork
    ? legalHolidayWorkTime(calendar, timecards)
    : legalHolidayWorkTime(calendar, timecards) + regularHolidayWorkTime(calendar, timecards);

export const legalHolidayWorkTime = (calendar, timecards) =>
  timecards
    .filter((_) => calendar.some(({ date, type }) => isEqual(_.date.toDate(), date) && type === 'legal-holiday'))
    .filter((_) => _.type === 'work')
    .map((_) => (_.to.seconds - _.from.seconds) / 60)
    .reduce((x, y) => x + y, 0);

export const regularHolidayWorkTime = (calendar, timecards) =>
  timecards
    .filter((_) => calendar.some(({ date, type }) => isEqual(_.date.toDate(), date) && type === 'regular-holiday'))
    .filter((_) => _.type === 'work')
    .map((_) => (_.to.seconds - _.from.seconds) / 60)
    .reduce((x, y) => x + y, 0);
