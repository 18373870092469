import { DocumentReference, DocumentData } from 'firebase/firestore';
import { useState, useEffect } from 'react';

export default function useDocumentSubscription<T>(
  ref: DocumentReference<DocumentData> | null | undefined,
  dependencies: any[] = []
): T | null {
  const [item, setItem] = useState<T | null>(null);
  useEffect(() => {
    if (!ref) return;
    // @ts-ignore v9 compactを外すまで
    const unsubscribe = ref.onSnapshot((doc) => {
      if (doc.exists) {
        setItem({ id: doc.id, ref, ...doc.data() } as T);
      } else {
        setItem(null);
      }
    });
    return unsubscribe;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
  return item;
}
