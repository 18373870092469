import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, FormGroup, Input, Label, Alert } from 'reactstrap';
import { toast } from 'react-toastify';
import { isEqual } from 'date-fns';
import { parseDate, formatDate } from '../../utils/time';
import { startOfMonth } from '../../shared/utils/date';
import { getCalendar } from '../../utils/calendar';

export default function TransferHolidayModal(props) {
  const { userRef, date: from, isOpen, onClickClose, publicHolidays, transferHolidays, transferHoliday = null, shifts } = props;
  const transferFrom = transferHoliday
    ? formatDate(transferHoliday.from.toDate(), 'yyyy-MM-dd')
    : formatDate(from, 'yyyy-MM-dd');
  const [transferTo, setTransferTo] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const isUnsubmittable = !transferTo || !!errorMessage;

  useEffect(() => {
    if (transferTo && !transferHoliday) {
      const calendar = getCalendar({ start: startOfMonth(parseDate(transferTo)), publicHolidays, transferHolidays, shifts });
      const { type } = calendar.find((_) => isEqual(_.date, parseDate(transferTo)));
      if (type !== 'weekday') {
        setErrorMessage('振替先が勤務日ではありません');
        return;
      }
      if (transferHolidays.some(_ => isEqual(_.from.toDate(), parseDate(transferTo)))) {
        setErrorMessage('振替先は既に振替られた勤務日です');
        return;
      }
    }
    setErrorMessage('');
  }, [transferTo, publicHolidays, transferHolidays, transferHoliday, shifts]);

  useEffect(() => {
    setTransferTo(transferHoliday ? formatDate(transferHoliday.to.toDate(), 'yyyy-MM-dd') : '')
  }, [transferHoliday])

  const onSubmit = async () => {
    if (isUnsubmittable) return;
    try {
      if (transferHoliday) {
        await transferHoliday.ref.delete();
        toast.success('休日振替を解除しました'); 
      } else {
        await userRef.collection('transferHolidays').add({ from, to: parseDate(transferTo) });
        toast.success('休日振替を設定しました'); 
      }
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={onClickClose}>
      <ModalHeader>休日の振替{transferHoliday ? '解除' : '設定'}</ModalHeader>
      <Form>
        <ModalBody>
          <FormGroup>
            <Label>振替元</Label>
            <Input type="date" value={transferFrom} disabled />
          </FormGroup>
          <FormGroup>
            <Label>振替先</Label>
            <Input
              type="date"
              value={transferTo}
              onChange={({ target: { value } }) => setTransferTo(value)}
              disabled={!!transferHoliday}
            />
          </FormGroup>
          {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
        </ModalBody>
        <ModalFooter>
          <Button className="cancel" color="secondary" onClick={onClickClose}>
            閉じる
          </Button>
          <Button className="cancel" color="primary" onClick={onSubmit} disabled={isUnsubmittable}>
            {transferHoliday ? '解除' : '振替'}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
