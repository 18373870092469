import { formatDate } from '../utils/time';
import { parseDate } from '../shared/utils/date';
import { differenceInMinutes, addDays, subDays, addYears, addMonths, subMonths, isEqual } from 'date-fns';
import { getCalendar } from '../utils/calendar';
import { orderBy, head, last } from 'lodash';

export class Organization {
  constructor({
    id = "",
    name = "",
    isFlextime = false,
    workTimeBegin = "",
    workTimeEnd = "",
    workTimePerDay = 0,
    startOfPeriod = "",
    disabled = false,
    showMonthlyReport = false,
    workHourInterval = 0,
    lunchTime = 0,
    isViewAnotherOvertime = false,
    excessStatutoryHolidayIsWork = false,
    useHalfPaidHoliday = false,
  }) {
    this.id = id;
    this.name = name;
    this.isFlextime = isFlextime;
    this.workTimeBegin = workTimeBegin;
    this.workTimeEnd = workTimeEnd;
    this.workTimePerDay = workTimePerDay;
    this.startOfPeriod = startOfPeriod;
    this.disabled = disabled;
    this.showMonthlyReport = showMonthlyReport;
    this.workHourInterval = workHourInterval;
    this.lunchTime = lunchTime;
    this.isViewAnotherOvertime = isViewAnotherOvertime;
    this.excessStatutoryHolidayIsWork = excessStatutoryHolidayIsWork;
    this.useHalfPaidHoliday = useHalfPaidHoliday;
  }

  workTimeBeginByDate(date) {
    return parseDate(`${formatDate(date, 'yyyy-MM-dd')} ${this.workTimeBegin}`);
  }

  workTimeEndByDate(date) {
    return parseDate(`${formatDate(date, 'yyyy-MM-dd')} ${this.workTimeEnd}`);
  }

  toObject() {
    return {
      name: this.name,
      isFlextime: this.isFlextime,
      workTimeBegin: this.workTimeBegin,
      workTimeEnd: this.workTimeEnd,
      workTimePerDay: this.workTimePerDay,
      startOfPeriod: this.startOfPeriod,
      disabled: this.disabled,
      showMonthlyReport: this.showMonthlyReport,
      workHourInterval: this.workHourInterval,
      lunchTime: this.lunchTime,
      isViewAnotherOvertime: this.isViewAnotherOvertime,
      excessStatutoryHolidayIsWork: this.excessStatutoryHolidayIsWork,
      useHalfPaidHoliday: this.useHalfPaidHoliday,
    };
  }

  get workTimeMinutesPerDay() {
    if (this.isFlextime) {
      return Number(this.workTimePerDay) * 60;
    } else {
      const begin = parseDate(`2000-01-01 ${this.workTimeBegin}`);
      const end = parseDate(`2000-01-01 ${this.workTimeEnd}`);
      const diff = differenceInMinutes(end, begin);
      if (diff < 6 * 60) {
        return diff;
      } else if (diff < 8 * 60) {
        return diff - 45;
      } else {
        return diff - 60;
      }
    }
  }

  prescribedWorkTime(startDay, publicHolidays, leaves, shifts, workPerWeek) {
    const workTypes = this.excessStatutoryHolidayIsWork ? ['weekday', 'regular-holiday'] : ['weekday'];
    const workDays = getCalendar({ start: startDay, publicHolidays, shifts }).filter((_) => workTypes.includes(_.type));
    const leaveTimeMinutes = leaves.reduce(
      (x, y) => x + y.leaveTimeOfMonth(startDay, publicHolidays, this.workTimeMinutesPerDay),
      0
    );
    return (workDays.length * this.workTimeMinutesPerDay - leaveTimeMinutes) * workPerWeek;
  }

  periodRange(period) {
    const [month, day] = this.startOfPeriod.split('-');
    const begin = new Date(period, Number(month) - 1, Number(day));
    const end = subDays(addYears(begin, 1), 1);
    return {
      begin: formatDate(begin, 'yyyy-MM-dd'),
      end: formatDate(end, 'yyyy-MM-dd'),
    };
  }

  startOfOrganizationsMonth(month) {
    if (!this.startOfPeriod) return month;

    const [, day] = this.startOfPeriod.split('-');
    if (Number(day) === 1) return month;
    return addDays(subMonths(month, 1), Number(day) - 1);
  }

  endOfOrganizationsMonth(month) {
    return subDays(addMonths(this.startOfOrganizationsMonth(month), 1), 1);
  }

  hasWorkHourIntervalAlert = (timecards, date) => {
    if (!this.workHourInterval) return false;

    // 当日中にインターバル以上の空き時間がある場合はOK
    const targets = orderBy(
      timecards.filter((_) => isEqual(_.date.toDate(), date)),
      [(_) => _.from.seconds]
    );
    if (!targets.length) return false;
    const hasNotIntervalByDate = targets.reduce((prev, current) => {
      if (!prev) return false;
      if (current.from.seconds - prev.to.seconds >= this.workHourInterval * 60 * 60) return false;
      return current;
    });
    if (!hasNotIntervalByDate) return false;

    const lastOfYesterday = last(
      orderBy(
        timecards.filter((_) => isEqual(_.date.toDate(), subDays(date, 1))),
        [(_) => _.from.seconds]
      )
    );
    const firstOfToday = head(
      orderBy(
        timecards.filter((_) => isEqual(_.date.toDate(), date, 1)),
        [(_) => _.from.seconds]
      )
    );
    // 前日のタイムカードがない場合はOK
    if (!lastOfYesterday) return false;
    // 前日の終了から当日の開始までにインターバル以上の空き時間がある場合はOK
    if (firstOfToday.from.seconds - lastOfYesterday.to.seconds >= this.workHourInterval * 60 * 60) return false;

    return true;
  };
}
