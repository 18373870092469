import React from 'react';
import { useToggle } from 'react-use';
import { Card, CardBody, Table, Button } from 'reactstrap';

import Page from '../../../hocs/Page';
import useCollectionSubscription from '../../../hooks/useCollectionSubscription';
import { db } from '../../../../firebase';
import OrganizationRow from './OrganizationRow';
import OrganizationModal from './OrganizationModal';
import Invitations from './Invitations';

const organizationsRef = db.collection('organizations');

export default Page(function Organizations(props) {
  const organizations = useCollectionSubscription(organizationsRef.orderBy('name'), []);
  const [showModal, toggle] = useToggle();

  return (
    <div className="container py-5 position-relative">
      <Card className="my-5">
        <CardBody>
          <div className="d-flex justify-content-center">
            <h4>組織管理</h4>
          </div>
          <div className="d-flex mb-2">
            <Button className="ml-auto" color="info" onClick={toggle}>
              <i className="fas fa-edit mr-1"></i>組織の追加
            </Button>
          </div>
          <Table>
            <thead>
              <tr>
                <th>組織名</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {organizations.map((organization) => {
                return <OrganizationRow key={organization.id} organization={organization} />;
              })}
            </tbody>
          </Table>
          <OrganizationModal isOpen={showModal} onClickClose={toggle} />
        </CardBody>
      </Card>
      <Invitations></Invitations>
    </div>
  );
});
