import { format, startOfDay as _startOfDay } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import ja from 'date-fns/locale/ja';

export const minToHHmm = (minutes) => {
  if (!minutes) return;
  return minToHHmmWithZero(minutes);
};

export const minToHHmmWithZero = (minutes) => {
  const min = (Math.abs(minutes) % 60).toString().padStart(2, '0');
  const hour = Math.floor(minutes / 60);
  return `${hour}:${min}`;
}

export const formatDate = (date, pattern) => format(utcToZonedTime(date, 'Asia/Tokyo'), pattern, { locale: ja });
export const parseDate = (string) => zonedTimeToUtc(string, 'Asia/Tokyo');
export const startOfDay = (date) => _startOfDay(utcToZonedTime(date));
