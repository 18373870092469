import { useLayoutEffect, useState } from 'react';

type Size = { normal: string; empty: string };

export default function useTimetableRowHeight() {
  const [size, setSize] = useState<Size>({ normal: '36px', empty: '34px' });

  useLayoutEffect(() => {
    function updateSize() {
      const zoomRatio = Math.round((window.outerWidth / window.innerWidth) * 100) / 100;
      if (zoomRatio < 0.75) {
        // ~67%
        setSize({ normal: '37px', empty: '35px' });
      } else if (zoomRatio >= 0.75 && zoomRatio < 0.8) {
        // 75%
        setSize({ normal: '37px', empty: '35px' });
      } else if (zoomRatio >= 0.8 && zoomRatio < 0.9) {
        // 80%
        setSize({ normal: '36px', empty: '34px' });
      } else if (zoomRatio >= 0.9 && zoomRatio < 1) {
        // 90%
        setSize({ normal: '36px', empty: '33px' });
      } else if (zoomRatio >= 1 && zoomRatio < 1.1) {
        // 100%
        setSize({ normal: '36px', empty: '34px' });
      } else if (zoomRatio >= 1.1 && zoomRatio < 1.25) {
        // 110%
        setSize({ normal: '35px', empty: '34px' });
      } else {
        // 125%~
        setSize({ normal: '36px', empty: '34px' });
      }
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}
