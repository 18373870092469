/* eslint-disable react-hooks/exhaustive-deps */
import { UAParser } from 'ua-parser-js';

const uaParser = new UAParser();

export default function useDevice() {
  const deviceType = uaParser.getDevice().type;
  const os = uaParser.getOS();
  const isMobile = deviceType === 'mobile';
  return { deviceType, os, isMobile };
}
