import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, FormGroup, Input, Label } from 'reactstrap';
import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';
import { isEmail, isMultibyte } from 'validator';
import { db, auth, EmailAuthProvider } from '../../firebase';

const invitationsRef = db.collection('invitations');

export default function InvitationModal(props) {
  const { isOpen, organization, role, onClickClose } = props;
  const [email, setEmail] = useState('');
  const [employeeNumber, setEmployeeNumber] = useState(null);
  const isUnsubmittable = !email || !isEmail(email) || isMultibyte(email);

  useEffect(() => {
    setEmail('');
    setEmployeeNumber(null);
  }, [isOpen]);

  const createInvitation = () => {
    const token = uuid();
    return invitationsRef
      .doc(token)
      .get()
      .then(({ exists, ref }) => {
        if (exists) return this.createInvitation();
        return ref.set({
          token,
          email,
          employeeNumber,
          organization: { id: organization.id, name: organization.name },
          role,
          createdAt: new Date(),
        });
      });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    if (isUnsubmittable) return;

    const providers = await auth.fetchSignInMethodsForEmail(email);
    if (providers.includes(EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD)) {
      toast.error('既に登録済みのユーザーです');
      return;
    }
    const hasInvitation = await invitationsRef
      .where('email', '==', email)
      .get()
      .then(({ docs }) => Boolean(docs.length));
    if (hasInvitation) {
      toast.error('既に招待済みのユーザーです');
      return;
    }

    try {
      await createInvitation();
      toast.success('招待メールの送信を予約しました');
      onClickClose();
    } catch (e) {
      console.error(e);
      toast.error('招待メールの送信に失敗しました');
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={onClickClose}>
      <ModalHeader>{role === 'admin' ? '管理者' : 'ユーザー'}招待</ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody>
        <FormGroup>
            <Label for="employeeNumber">社員番号</Label>
            <Input
              value={employeeNumber}
              type="number"
              id="employeeNumber"
              onChange={(e) => setEmployeeNumber(Number(e.target.value))}
            />
          </FormGroup>
          <FormGroup>
            <Label for="email">メールアドレス</Label>
            <Input
              value={email}
              valid={!isUnsubmittable}
              invalid={email ? isUnsubmittable : false}
              type="email"
              id="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button className="cancel" color="secondary" onClick={onClickClose}>
            閉じる
          </Button>
          <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable}>
            招待する
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
