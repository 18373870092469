import React, { useState } from 'react';
import { Card, CardBody, Table, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { uniqBy } from 'lodash';

import Page from '../../hocs/Page';
import useUsersSubscription from '../../hooks/useUsersSubscription';
import useDevice from '../../hooks/useDevice';
import Invitations from './Invitations';
import UserSettingModal from '../../modals/UserSettingModal';
import { roleNames } from '../../../shared/config';
import { functions } from '../../../firebase';

const updateUserDisabled = functions.httpsCallable('updateUserDisabled');

export default Page(function UserManagement(props) {
  const { firebaseUser, organizationId } = props;
  const users = useUsersSubscription(organizationId);
  const [showModalUid, setShowModalUid] = useState();
  const { isMobile } = useDevice();
  const [searchValue, setSearchValue] = useState(null);
  const filterdUsers = users.filter((_) => (!searchValue || _.group === searchValue.value));

  const onStop = async ({
    target: {
      dataset: { uid },
    },
  }) => {
    if (!window.confirm('本当に停止してよろしいですか？') || !uid) return;

    try {
      await updateUserDisabled({ uid, disabled: true });
      toast.success('ユーザーを停止しました');
    } catch (e) {
      console.error(e);
      toast.error('ユーザー停止に失敗しました');
    }
  };

  const onResume = async ({
    target: {
      dataset: { uid },
    },
  }) => {
    if (!window.confirm('本当に再開してよろしいですか？') || !uid) return;

    try {
      await updateUserDisabled({ uid, disabled: false });
      toast.success('ユーザーを再開しました');
    } catch (e) {
      console.error(e);
      toast.error('ユーザー再開に失敗しました');
    }
  };

  return (
    <div className="container py-5 position-relative">
      <Card className="my-5">
        <CardBody>
          <div className="d-flex justify-content-center">
            <h4>ユーザー管理</h4>
          </div>
          <div className="d-flex justify-content-end py-3">
            <Select
              value={searchValue}
              onChange={(option) => setSearchValue(option)}
              className={`form-select mr-1 ${isMobile ? 'w-100' : 'w-25'}`}
              options={uniqBy(
                users.filter((_) => _.group).map((_) => ({ label: _.group, value: _.group })),
                'value'
              )}
              isClearable
              placeholder="グループで絞り込み..."
            />
          </div>
          <Table>
            <thead>
              <tr>
                <th>No.</th>
                <th>名前</th>
                {isMobile || (
                  <>
                    <th>グループ</th>
                    <th>メールアドレス</th>
                  </>
                )}
                <th>権限</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filterdUsers.map((user) => {
                const { id, displayName, email, group, role, disabled, employeeNumber } = user;
                return (
                  <tr key={id}>
                    <td>{employeeNumber || ''}</td>
                    <td>{displayName}</td>
                    {isMobile || (
                      <>
                        <td>{group}</td>
                        <td>{email}</td>
                      </>
                    )}
                    <td>{roleNames[role]}</td>
                    <td>
                      <Button className="mr-1" color="info" onClick={() => setShowModalUid(id)}>
                        <i className="fas fa-edit"></i>
                        {isMobile ? '' : <span className="ml-1">編集</span>}
                      </Button>
                      {firebaseUser.uid !== id &&
                        !user.isSysAdmin &&
                        (disabled ? (
                          <Button color="warning" data-uid={id} onClick={onResume}>
                            <i className="fas fa-play"></i>
                            {isMobile ? '' : <span className="ml-1">再開</span>}
                          </Button>
                        ) : (
                          <Button color="danger" data-uid={id} onClick={onStop}>
                            <i className="fas fa-stop"></i>
                            {isMobile ? '' : <span className="ml-1">停止</span>}
                          </Button>
                        ))}
                      <UserSettingModal
                        isOpen={showModalUid === id}
                        user={user}
                        organizationId={organizationId}
                        firebaseUser={firebaseUser}
                        onClickClose={() => setShowModalUid(null)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <Invitations {...props}></Invitations>
    </div>
  );
});
