import React, { useEffect } from 'react';
import { Card, CardBody, Form, FormGroup, Label, Input, Button, FormFeedback, InputGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

import Page from '../../../hocs/Page';
import useDocumentSubscription from '../../../hooks/useDocumentSubscription';
import { db, FieldValue } from '../../../../firebase';
import { DEFAULT_LUNCH_TIME } from '../../../../shared/config';

const organizationsRef = db.collection('organizations');

export default Page(function OrganizationSetting(props) {
  const { organizationId } = props;
  const organization = useDocumentSubscription(organizationId && organizationsRef.doc(organizationId), [organizationId]);
  const { register, handleSubmit, errors, setValue, watch } = useForm({ mode: 'onBlur' });
  const isFlextime = watch('is_flex_time', false);
  const isUnsubmittable = Boolean(Object.values(errors).length);

  useEffect(() => {
    if (organization) {
      setValue('organization_name', organization.name);
      setValue('is_flex_time', organization.isFlextime || false);
      setValue('start_of_period', organization.startOfPeriod || '04-01');
      setValue('show_monthly_report', organization.showMonthlyReport);
      setValue('work_hour_interval', organization.workHourInterval || 0);
      setValue('lunch_time', organization.lunchTime || DEFAULT_LUNCH_TIME);
      setValue('excess_statutory_holiday_is_work', organization.excessStatutoryHolidayIsWork || false);
      setValue('use_half_paid_holiday', organization.useHalfPaidHoliday || false);
    }
  }, [organization, setValue]);

  useEffect(() => {
    if (organization) {
      setValue('work_time_per_day', organization.workTimePerDay || 0);
      setValue('work_time_begin', organization.workTimeBegin || '');
      setValue('work_time_end', organization.workTimeEnd || '');
      setValue('is_view_another_overtime', organization.isViewAnotherOvertime || false);
    }
  }, [organization, setValue, isFlextime]);

  const onSubmit = async (values) => {
    const {
      organization_name: name,
      work_time_per_day: workTimePerDay = 0,
      work_time_begin: workTimeBegin = '',
      work_time_end: workTimeEnd = '',
      is_flex_time: isFlextime,
      start_of_period: startOfPeriod,
      show_monthly_report: showMonthlyReport,
      work_hour_interval: workHourInterval = 0,
      lunch_time: lunchTime = DEFAULT_LUNCH_TIME,
      is_view_another_overtime: isViewAnotherOvertime = false,
      excess_statutory_holiday_is_work: excessStatutoryHolidayIsWork = false,
      use_half_paid_holiday: useHalfPaidHoliday = false,
    } = values;
    try {
      await organization.ref.set(
        {
          name,
          workTimeBegin,
          workTimeEnd,
          isFlextime,
          workTimePerDay,
          startOfPeriod,
          showMonthlyReport,
          workHourInterval,
          lunchTime,
          isViewAnotherOvertime,
          excessStatutoryHolidayIsWork,
          useHalfPaidHoliday,
          updatedAt: FieldValue.serverTimestamp(),
        },
        { merge: true }
      );
      toast.success('更新しました');
    } catch (e) {
      console.error(e);
      toast.error('更新に失敗しました');
    }
  };

  return (
    <div className="container py-5 position-relative">
      <Card className="my-5">
        <CardBody>
          <div className="d-flex justify-content-center">
            <h4>組織設定</h4>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup className="py-2 mb-2">
              <Label>組織名称</Label>
              <Input
                name="organization_name"
                type="text"
                valid={!Boolean(errors.organization_name)}
                invalid={Boolean(errors.organization_name)}
                innerRef={register({ required: '組織名称が未入力です' })}
              />
              {errors.organization_name && <FormFeedback>{errors.organization_name.message}</FormFeedback>}
            </FormGroup>

            <FormGroup check className="mb-2">
              <Label check>
                <Input name="is_flex_time" type="checkbox" innerRef={register} />
                フレックスタイム制を利用する
              </Label>
            </FormGroup>
            {isFlextime ? (
              <>
                <FormGroup className="py-2 mb-2">
                  <Label>勤務時間/日</Label>
                  <Input
                    name="work_time_per_day"
                    type="number"
                    valid={!Boolean(errors.work_time_per_day)}
                    invalid={Boolean(errors.work_time_per_day)}
                    max="24"
                    min="0"
                    innerRef={register(
                      isFlextime
                        ? {
                            valueAsNumber: true,
                            required: '勤務時間/日が未入力です',
                            max: { value: 24, message: '24以下で指定して下さい' },
                            min: { value: 0, message: '0以上で指定して下さい' },
                          }
                        : {}
                    )}
                  />
                  {errors.work_time_per_day && <FormFeedback>{errors.work_time_per_day.message}</FormFeedback>}
                </FormGroup>
                <FormGroup check className="mb-2">
                  <Label check>
                    <Input name="is_view_another_overtime" type="checkbox" innerRef={register} />
                    「残業時間」表示を「超過時間」表示に切り替える
                  </Label>
                  <div>
                    <small>※超過時間の場合、1日単位で「勤務時間/日」と実際の勤務時間との差分を表示します。</small>
                  </div>
                </FormGroup>
              </>
            ) : (
              <FormGroup className="py-2 mb-2">
                <Label>始業/終業時刻</Label>
                <span className="d-flex align-items-center">
                  <div className="mr-1" style={{ width: '100%' }}>
                    <Input
                      name="work_time_begin"
                      type="time"
                      valid={!Boolean(errors.work_time_begin)}
                      invalid={Boolean(errors.work_time_begin)}
                      innerRef={register(isFlextime ? {} : { required: '始業時刻が未入力です' })}
                    />
                    {errors.work_time_begin && <FormFeedback>{errors.work_time_begin.message}</FormFeedback>}
                  </div>
                  　〜　
                  <div style={{ width: '100%' }}>
                    <Input
                      name="work_time_end"
                      className="mr-1"
                      type="time"
                      valid={!Boolean(errors.work_time_end)}
                      invalid={Boolean(errors.work_time_end)}
                      innerRef={register(isFlextime ? {} : { required: '終業時刻が未入力です' })}
                    />
                    {errors.work_time_end && <FormFeedback>{errors.work_time_end.message}</FormFeedback>}
                  </div>
                </span>
              </FormGroup>
            )}
            <FormGroup className="py-2 mb-2">
              <Label>休憩時間（分）</Label>
              <InputGroup>
                <Input
                  name="lunch_time"
                  type="number"
                  valid={!Boolean(errors.lunch_time)}
                  invalid={Boolean(errors.lunch_time)}
                  max="1440"
                  min="0"
                  innerRef={register({
                    valueAsNumber: true,
                    required: '休憩時間が未入力です',
                    max: { value: 1440, message: '1440以下で指定して下さい' },
                    min: { value: 0, message: '0以上で指定して下さい' },
                  })}
                />
                {errors.lunch_time && <FormFeedback>{errors.lunch_time.message}</FormFeedback>}
              </InputGroup>
            </FormGroup>

            <FormGroup className="py-2 mb-2">
              <Label>年度開始月日</Label>
              <InputGroup>
                <Input
                  name="start_of_period"
                  type="text"
                  valid={!Boolean(errors.start_of_period)}
                  invalid={Boolean(errors.start_of_period)}
                  innerRef={register({
                    required: '年度開始月日が未入力です',
                    pattern: { value: /^[0-1][1-9]-[0-3][1-9]$/, message: 'MM-DDの形式で入力してください' },
                  })}
                />
                {errors.start_of_period && <FormFeedback>{errors.start_of_period.message}</FormFeedback>}
              </InputGroup>
            </FormGroup>

            <FormGroup check className="py-2 mb-2">
              <Label check>
                <Input name="show_monthly_report" type="checkbox" innerRef={register} />
                月間集計を一般ユーザーに公開する
              </Label>
            </FormGroup>

            <FormGroup check className="mb-2">
              <Label check>
                <Input name="excess_statutory_holiday_is_work" type="checkbox" innerRef={register} />
                所定休日（土・祝）を勤務日にする
              </Label>
            </FormGroup>

            <FormGroup check className="mb-2">
              <Label check>
                <Input name="use_half_paid_holiday" type="checkbox" innerRef={register} />
                半休を利用可能にする
              </Label>
            </FormGroup>

            <FormGroup className="py-2 mb-2">
              <Label>
                勤務間インターバル<small className="ml-2">※指定しない場合は0を入力して下さい</small>
              </Label>
              <InputGroup>
                <Input
                  name="work_hour_interval"
                  type="number"
                  max="24"
                  min="0"
                  valid={!Boolean(errors.work_hour_interval)}
                  invalid={Boolean(errors.work_hour_interval)}
                  innerRef={register({
                    valueAsNumber: true,
                    max: { value: 24, message: '24以下で指定して下さい' },
                    min: { value: 0, message: '0以上で指定して下さい' },
                  })}
                />
                {errors.work_hour_interval && <FormFeedback>{errors.work_hour_interval.message}</FormFeedback>}
              </InputGroup>
            </FormGroup>

            <Button color="primary" disabled={isUnsubmittable} type="submit" onClick={handleSubmit(onSubmit)}>
              <i className="fas fa-edit mr-1"></i>更新
            </Button>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
});
