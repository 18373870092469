import React from 'react';
import { useToggle } from 'react-use';
import { Button } from 'reactstrap';

import { db, Timestamp } from '../../../../firebase';
import useCollectionSubscription from '../../../hooks/useCollectionSubscription';
import ShiftSettingModal from '../../../modals/ShiftSettingModal';
import { Shift } from '../../../../models';

const organizationsRef = db.collection('organizations');

export default function ShiftSettingRow(props) {
  const { organization, user, month } = props;
  const [showModal, toggle] = useToggle(false);
  // const periodRange = organization.periodRange(period);
  const from = organization.startOfOrganizationsMonth(month);
  const to = organization.endOfOrganizationsMonth(month);
  const shiftsRef = organizationsRef.doc(organization.id).collection('users').doc(user.id).collection('shifts');
  const shifts = useCollectionSubscription(
    shiftsRef.where('date', '>=', Timestamp.fromDate(from)).where('date', '<=', Timestamp.fromDate(to)),
    [organization, user]
  ).map((_) => new Shift(_));

  const getCount = (type) => {
    if (!shifts || !shifts.length) return '未設定';
    return shifts.filter(_ => _.type === type).length;
  }

  return (
    <tr>
      <td>{user.displayName}</td>
      <td>{user.group}</td>
      <td>{getCount('work')}</td>
      <td>{getCount('holiday')}</td>
      <td>
        <Button color="primary" size="sm" onClick={toggle}>
          <i className="fas fa-edit"></i>
        </Button>
        <ShiftSettingModal {...props} isOpen={showModal} onClose={toggle} from={from} to={to} shifts={shifts} />
      </td>
    </tr>
  );
}
