import React from 'react';
import { isWithinInterval } from 'date-fns';

import Page from '../../hocs/Page';
import Header from './Header';
import BarChart from './BarChart';
import Timetable from './Timetable';
import useCollectionSubscription from '../../hooks/useCollectionSubscription';
import useDocumentSubscription from '../../hooks/useDocumentSubscription';
import { db, Timestamp } from '../../../firebase';
import { monthFromSearch } from '../../../utils/calendar';
import { Organization, User, Leave, WorkStyle, Shift, GrantedHoliday } from '../../../models';
import useDevice from '../../hooks/useDevice';
import { AssignedHoliday, PublicHoliday, Timecard, TransferHoliday } from '../../../../types';

const usersRef = db.collection('users');
const organizationsRef = db.collection('organizations');

export default Page(function Dashboard(props: any) {
  const {
    user,
    location: { search },
    match: {
      params: { userId },
    },
    organizationId,
  } = props;
  const { isMobile } = useDevice();
  const month = monthFromSearch(search);
  const uid = userId || (user && user.id);
  const organizationRef = organizationId && organizationsRef.doc(organizationId);
  const organization = new Organization(useDocumentSubscription<Organization>(organizationRef, [organizationId]) || {});
  const userRef = organizationRef && uid && organizationRef.collection('users').doc(uid);
  const workStyles = useCollectionSubscription<WorkStyle>(userRef && userRef.collection('workStyles'), [
    organizationId,
    uid,
  ]).map((_) => new WorkStyle(_));
  const startDay = organization.startOfOrganizationsMonth(month);
  const workStyle = workStyles.find(({ from, to }) => isWithinInterval(startDay, { start: from.toDate(), end: to.toDate() }));
  const displayUser = useDocumentSubscription<User>(uid && usersRef.doc(uid), [uid]);
  const timecards = useCollectionSubscription<Timecard>(
    userRef &&
      userRef
        .collection('timecards')
        .orderBy('date')
        .startAt(Timestamp.fromDate(organization.startOfOrganizationsMonth(month)))
        .endAt(Timestamp.fromDate(organization.endOfOrganizationsMonth(month))),
    [user, search, organizationId]
  );
  const assignedHolidays = useCollectionSubscription<AssignedHoliday>(userRef && userRef.collection('assignedHolidays'), [uid, organizationId]);
  const grantedHolidays = useCollectionSubscription<GrantedHoliday>(userRef && userRef.collection('grantedHolidays'), [uid, organizationId]);
  const publicHolidays = useCollectionSubscription<PublicHoliday>(
    organizationRef && organizationRef.collection('publicHolidays').orderBy('date'),
    [organizationId]
  );
  const transferHolidays = useCollectionSubscription<TransferHoliday>(userRef && userRef.collection('transferHolidays'), [
    uid,
    organizationId,
  ]);
  const leaves = useCollectionSubscription<Leave>(userRef && userRef.collection('leaves'), [uid, organizationId]).map(
    (_) => new Leave(_)
  );
  const shifts = useCollectionSubscription<Shift>(user.useShift && userRef && userRef.collection('shifts'), [
    user,
    organizationId,
  ]).map((_) => new Shift(_));

  if (!user || !displayUser) return <></>;

  const params = {
    month,
    displayUser,
    userRef,
    workStyle,
    organization,
    timecards,
    assignedHolidays,
    grantedHolidays,
    publicHolidays,
    transferHolidays,
    leaves,
    shifts,
  };
  return (
    <div>
      <div className={`container-full position-relative ${isMobile ? '' : 'p-5'}`}>
        <div className="pb-3">
          <Header {...props} {...params}></Header>
        </div>
        {isMobile ? (
          <div>
            <BarChart {...props} {...params}></BarChart>
            <Timetable {...props} {...params}></Timetable>
          </div>
        ) : (
          <div className="row">
            <div className="col">
              <BarChart {...props} {...params}></BarChart>
            </div>
            <div className="col">
              <Timetable {...props} {...params}></Timetable>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});
