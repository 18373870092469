import React, { useState } from 'react';
import { useToggle } from 'react-use';
import { Card, CardBody, Table, Button } from 'reactstrap';
import qs from 'qs';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { uniqBy } from 'lodash';

import Page from '../../../hocs/Page';
import useUsersSubscription from '../../../hooks/useUsersSubscription';
import useDocumentSubscription from '../../../hooks/useDocumentSubscription';
import { db, Timestamp } from '../../../../firebase';
import { paidHolidayTypes } from '../../../../shared/config';
import PeriodSettingRow from './PeriodSettingRow';
import CsvUploadModal from '../../../modals/CsvUploadModal';
import { parseDate } from '../../../../shared/utils/date';
import { getExpiredDate } from '../../../../shared/utils/holiday';
import { Organization } from '../../../../models';
import useDevice from '../../../hooks/useDevice';

const periodFromSearch = (search) => {
  const { period } = qs.parse(search.slice(1));
  return Number(period) || new Date().getFullYear();
};

const organizationsRef = db.collection('organizations');

export default Page(function PeriodSetting(props) {
  const {
    history,
    location: { search },
    organizationId,
  } = props;
  const period = periodFromSearch(search);
  const users = useUsersSubscription(organizationId);
  const usersRef = organizationsRef.doc(organizationId).collection('users');
  const organization = new Organization(useDocumentSubscription(organizationsRef.doc(organizationId), [organizationId]) || {});
  const [showModal, toggle] = useToggle();
  const { isMobile } = useDevice();
  const [searchValue, setSearchValue] = useState(null);
  const filterdUsers = users.filter((_) => (!searchValue || _.group === searchValue.value));

  const onClickChangePeriod = (period, event) => {
    const queries = qs.parse(search.slice(1));
    history.replace({ search: `?${qs.stringify({ ...queries, period })}` });
  };

  const validateCsv = (rows) => {
    const errors = rows
      .map((row, i) => {
        const name = row['名前'];
        if (!name) return `${i + 1}行目：ヘッダに名前が無いか、名前が空欄です。`;
        if (!users.map((_) => _.displayName).includes(name)) return `${i + 1}行目：${name}というユーザーが見つかりません。`;
        const date = row['付与日'] && row['付与日'].replace(/\//g, '-');
        if (!date) return `${i + 1}行目：ヘッダに付与日が無いか、付与日が空欄です。`;
        if (parseDate(date).toString() === 'Invalid Date') return `${i + 1}行目：不正な日付です。[${date}]`;
        const type = row['種別'] && row['種別'].replace(/（/g, '(').replace(/）/g, ')');
        if (!type) return `${i + 1}行目：ヘッダに種別が無いか、種別が空欄です。`;
        if (!paidHolidayTypes.map((_) => _.label).includes(type)) return `${i + 1}行目：不正な種別です。[${type}]`;
        const value = row['数量'];
        if (!value) return `${i + 1}行目：ヘッダに数量が無いか、数量が空欄、もしくは0です。`;
        if (Number.isNaN(Number(value))) return `${i + 1}行目：不正な数量です。[${value}]`;

        return null;
      })
      .filter((_) => _);
    return errors.length ? { valid: false, message: errors.join('\n') } : { valid: true };
  };

  const onCsvImport = async (rows) => {
    const { valid, message } = validateCsv(rows);
    if (!valid) {
      alert(message);
      return;
    }

    await Promise.all(
      rows.map(async (row) => {
        const name = row['名前'];
        const date = row['付与日'].replace(/\//g, '-');
        const type = row['種別'].replace(/（/g, '(').replace(/）/g, ')');
        const value = row['数量'];

        const user = users.find((_) => _.displayName === name);
        const selectedType = paidHolidayTypes.find((_) => _.label === type);
        const [month] = date.match(/\d{4}-\d{2}/);
        const expired = getExpiredDate(parseDate(date), selectedType.type);
        const grantedHolidaysRef = usersRef.doc(user.id).collection('grantedHolidays');
        const querySnapshot = await grantedHolidaysRef
          .where('date', '==', Timestamp.fromDate(parseDate(date)))
          .where('type', '==', selectedType.type)
          .get();
        if (querySnapshot.empty) {
          return grantedHolidaysRef.add({
            month: parseDate(month),
            date: parseDate(date),
            type: selectedType.type,
            value: selectedType.format === 'time' ? Number(value) * 60 : Number(value),
            expired,
          });
        } else {
          const [{ id: documentId }] = querySnapshot.docs;
          return grantedHolidaysRef.doc(documentId).set(
            {
              value: selectedType.format === 'time' ? Number(value) * 60 : Number(value),
            },
            { merge: true }
          );
        }
      })
    );

    toast.success('CSVインポートが完了しました。');
    toggle(false);
  };

  return (
    <div className="container py-5 position-relative">
      <Card className="my-5">
        <CardBody>
          <div className="d-flex justify-content-between py-3 align-items-baseline">
            <h3 className="d-flex align-items-end">
              <Button className="mr-1" color="primary" size="sm" onClick={onClickChangePeriod.bind(this, period - 1)}>
                <i className="fas fa-chevron-left"></i>
              </Button>
              {`${period}年度`}
              <Button className="ml-1" color="primary" size="sm" onClick={onClickChangePeriod.bind(this, period + 1)}>
                <i className="fas fa-chevron-right"></i>
              </Button>
            </h3>
            <Button color="primary" onClick={toggle}>
              CSVインポート
            </Button>
          </div>
          <div className="d-flex justify-content-center">
            <h4>休暇設定</h4>
          </div>
          <div className="d-flex justify-content-end py-3">
            <Select
              value={searchValue}
              onChange={(option) => setSearchValue(option)}
              className={`form-select mr-1 ${isMobile ? 'w-100' : 'w-25'}`}
              options={uniqBy(
                users.filter((_) => _.group).map((_) => ({ label: _.group, value: _.group })),
                'value'
              )}
              isClearable
              placeholder="グループで絞り込み..."
            />
          </div>
          <Table>
            <thead>
              <tr>
                <th>名前</th>
                {paidHolidayTypes.map(({ label }) => (
                  <th key={label}>{label}</th>
                ))}
                <th>休業</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filterdUsers.map((user) => (
                <PeriodSettingRow key={user.id} organization={organization} user={user} period={period} userRef={usersRef.doc(user.id)} />
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <CsvUploadModal
        isOpen={showModal}
        onClickClose={toggle}
        callback={onCsvImport}
        description={
          <ul>
            <li>
              ヘッダは <b>”名前, 付与日, 種別, 数量"</b> として下さい。
            </li>
            <li>
              付与日は <b>"YYYY/MM/DD"（もしくは"YYYY-MM-DD"）</b> 形式で入力して下さい。
            </li>
            <li>
              数量は数値で入力して下さい。
              <br />
              （例）120時間 → 120, 20日 → 20
            </li>
          </ul>
        }
      />
    </div>
  );
});
