import { startOfDay, startOfMonth } from 'date-fns';
import { holidayTypes } from '../shared/config';
import { formatDate, minToHHmm } from '../utils/time';

export class AssignedHoliday {
  constructor({ id, from, to, type, timecardId, hidden = false, lunchTime = 0, matchWord = false, isHalfDate = false }) {
    this.id = id;
    this.from = from;
    this.to = to;
    this.type = type;
    this.timecardId = timecardId;
    this.hidden = hidden;
    this.lunchTime = lunchTime;
    this.matchWord = matchWord;
    this.isHalfDate = isHalfDate;
  }

  get month() {
    return startOfMonth(this.from.toDate());
  }

  get date() {
    return startOfDay(this.from.toDate());
  }

  get value() {
    if (this.isHalfDate) return 0.5;
    return this.isTime ? Math.floor((this.to.seconds - this.from.seconds) / 60) : 1;
  }

  get holidayType() {
    return holidayTypes.find((_) => _.type === this.type) || {};
  }

  get isTime() {
    return this.holidayType.format === 'time';
  }

  get formattedDate() {
    return formatDate(this.date.toDate(), 'yyyy-MM-dd');
  }

  get formattedValue() {
    return this.isTime ? minToHHmm(this.value) : this.value;
  }

  toObject() {
    return {
      month: this.month,
      date: this.date,
      from: this.from,
      to: this.to,
      value: this.value,
      type: this.type,
      timecardId: this.timecardId,
      hidden: this.hidden,
      lunchTime: this.lunchTime,
      matchWord: this.matchWord,
      isHalfDate: this.isHalfDate,
    };
  }
}
