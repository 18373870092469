import React from 'react';
import { useToggle } from 'react-use';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';

import OrganizationModal from './OrganizationModal';
import InvitationModal from '../../../modals/InvitationModal';
import { db, functions } from '../../../../firebase';

const usersRef = db.collection('users');
const updateUserDisabled = functions.httpsCallable('updateUserDisabled');

export default function OrganizationRow(props) {
  const { organization } = props;
  const [showEditModal, toggleEditModal] = useToggle();
  const [showInvitationModal, toggleInvitationModal] = useToggle();
  const [isLoading, toggleLoading] = useToggle();

  const { id, name, disabled } = organization;

  const fetchUserIds = () =>
    usersRef
      .where('organizationId', '==', id)
      .get()
      .then(({ docs }) => docs.map((doc) => doc.id));

  const onStop = async () => {
    if (!window.confirm('本当に停止してよろしいですか？')) return;
    toggleLoading();

    try {
      await organization.ref.set({ disabled: true }, { merge: true });
      const userIds = await fetchUserIds();
      await Promise.all(userIds.map((uid) => updateUserDisabled({ uid, disabled: true })));
      await toast.success('組織を停止しました');
    } catch (e) {
      console.error(e);
      toast.error('組織の停止に失敗しました');
    } finally {
      toggleLoading();
    }
  };

  const onResume = async () => {
    if (!window.confirm('本当に再開してよろしいですか？')) return;
    toggleLoading();

    try {
      await organization.ref.set({ disabled: false }, { merge: true });
      const userIds = await fetchUserIds();
      await Promise.all(userIds.map((uid) => updateUserDisabled({ uid, disabled: false })));
      toast.success('組織を再開しました');
    } catch (e) {
      console.error(e);
      toast.error('組織の再開に失敗しました');
    } finally {
      toggleLoading();
    }
  };

  return (
    <tr key={id}>
      <td>
        <a href={`/organizations/${id}`}>{name}</a>
      </td>
      <td>
        <Button color="info" onClick={toggleEditModal} className="mr-1">
          <i className="fas fa-edit mr-1"></i>編集
        </Button>
        <OrganizationModal isOpen={showEditModal} organization={organization} onClickClose={toggleEditModal} />
        <Button color="primary" onClick={toggleInvitationModal} className="mr-1">
          <span className="fas fa-plus mr-1" />
          管理者を招待
        </Button>
        {isLoading ? (
          <Button className="mr-1">
            <i className="fas fa-sync fa-spin mr-1"></i>
          </Button>
        ) : disabled ? (
          <Button color="warning" onClick={onResume} className="mr-1">
            <i className="fas fa-play mr-1"></i>
            再開
          </Button>
        ) : (
          <Button color="danger" onClick={onStop} className="mr-1">
            <i className="fas fa-stop mr-1"></i>
            停止
          </Button>
        )}
        <InvitationModal
          isOpen={showInvitationModal}
          organization={organization}
          role="admin"
          onClickClose={toggleInvitationModal}
        />
      </td>
    </tr>
  );
}
