const {
  format,
  startOfMonth: _startOfMonth,
  endOfMonth: _endOfMonth,
  addDays,
  startOfDay: _startOfDay,
  eachDayOfInterval,
  getDay,
  subMonths: _subMonths,
} = require('date-fns');
const { utcToZonedTime, zonedTimeToUtc } = require('date-fns-tz');
const ja = require('date-fns/locale/ja');

// TODO: フロントから呼ぶと locale must contain localize property エラーになるので呼ばないように
exports.formatDate = (date, pattern) => format(utcToZonedTime(date, 'Asia/Tokyo'), pattern, { locale: ja });
exports.parseDate = (string) => zonedTimeToUtc(string, 'Asia/Tokyo');
exports.startOfMonth = (date) => this.parseDate(_startOfMonth(utcToZonedTime(date, 'Asia/Tokyo')));
exports.endOfMonth = (date) => this.parseDate(_endOfMonth(utcToZonedTime(date, 'Asia/Tokyo')));
exports.startOfDay = (date) => this.parseDate(_startOfDay(utcToZonedTime(date, 'Asia/Tokyo')));
exports.yesterday = () => this.parseDate(_startOfDay(addDays(new Date(), -1)));
exports.eachDay = (begin, end) =>
  eachDayOfInterval({ start: utcToZonedTime(begin, 'Asia/Tokyo'), end: utcToZonedTime(end, 'Asia/Tokyo') }).map((date) =>
    zonedTimeToUtc(date, 'Asia/Tokyo')
  );
exports.isSunday = (date) => getDay(utcToZonedTime(date, 'Asia/Tokyo')) === 0;
exports.isSaturday = (date) => getDay(utcToZonedTime(date, 'Asia/Tokyo')) === 6;
exports.subMonths = (date, day) => this.parseDate(_subMonths(utcToZonedTime(date, 'Asia/Tokyo'), day));
