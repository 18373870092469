import React, { useMemo, useContext } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, FormGroup, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import { format as formatDate, startOfDay } from 'date-fns';
import ja from 'date-fns/locale/ja';
import Select from 'react-select';
import { holidayTypes as _holidayTypes } from '../../shared/config';
import { validate as validatePaidHolidayHalf } from '../../shared/utils/paidHolidayHalf';
import { AssignedHoliday } from '../../models';
import { getGrantedHoliday } from '../../shared/utils/holiday';
import { db, functions } from '../../firebase';
import { SyncContext } from '../contexts/SyncContext';
import useCollectionSubscription from '../hooks/useCollectionSubscription';

const completionLunchTime = functions.httpsCallable('completionLunchTime');
const syncJobsRef = db.collection('syncJobs');

export default function HolidayAssignModal(props) {
  const { userRef, isOpen, onClickClose, assignedHolidays, grantedHolidays, item = {}, items, useHalfPaidHoliday } = props;
  const { timecardId, start, end, title, group, from, to, date } = item;
  const datetime = useMemo(() => {
    if (!start || !end) return;
    const formattedDate = formatDate(date, 'yyyy/MM/dd(E)', { locale: ja });
    return `${formattedDate} ${formatDate(start, 'HH:mm')} 〜 ${formatDate(end, 'HH:mm')}`;
  }, [start, end, date]);
  const assignedHoliday = useMemo(() => {
    return from && to && assignedHolidays.find((_) => _.from.seconds === from.seconds && _.to.seconds === to.seconds);
  }, [assignedHolidays, from, to]);
  const {
    state: { isSyncing },
  } = useContext(SyncContext);
  const [syncJob] = useCollectionSubscription(syncJobsRef.orderBy('createdAt', 'desc').limit(1));
  const isAllSyncing = syncJob && !['error', 'completed'].includes(syncJob.status);
  const holidayTypes = useHalfPaidHoliday
    ? [
        ..._holidayTypes.slice(0, 2),
        {
          label: '有給休暇(半休)',
          type: 'paid-holiday-half',
          format: 'half-date',
        },
        ..._holidayTypes.slice(-4),
      ]
    : _holidayTypes;
  const options = holidayTypes.map(({ label, type }) => ({ label, value: type }));

  const onChangeValue = async ({ value }) => {
    const minutes = (to.seconds - from.seconds) / 60;
    const { unit, format, words } = holidayTypes.find((_) => _.type === value);
    const type = value === 'paid-holiday-half' ? 'paid-holiday-date' : value;
    if (unit && minutes % unit !== 0) {
      alert(`${unit}分単位でしか取得できません。`);
      return;
    }
    if (items.filter((_) => _.group === group).length !== 1 && format === 'date') {
      alert('1日単位でしか取得できません。');
      return;
    }
    const timecards = items.filter((_) => _.group === group).map(_ => ({ ..._, type: _.className }));
    if (format === 'half-date' && !validatePaidHolidayHalf(timecards, { from })) {
      alert('半休はランチ以前（以後）全てが休みの場合のみ取得できます。');
      return;
    }
    const grant = getGrantedHoliday(
      grantedHolidays,
      assignedHolidays,
      startOfDay(from.toDate()),
      startOfDay(from.toDate()),
      type
    );
    if (
      !['unpaid-leave', 'late-or-early'].includes(type) &&
      ((format === 'date' && grant < 1) || (format === 'half-date' && grant < 0.5) || (format === 'time' && grant < minutes))
    ) {
      alert('付与数が不足しています。');
      return;
    }
    const matchWord = words && words.some((word) => title.includes(word));
    const isHalfDate = format === 'half-date';

    if (assignedHoliday) {
      const assignedHolidaysRef = userRef.collection('assignedHolidays');
      await assignedHolidaysRef
        .doc(assignedHoliday.id)
        .set({ ...new AssignedHoliday({ ...assignedHoliday, type, matchWord, isHalfDate }).toObject() }, { merge: true });
      toast.success('勤怠を変更しました');
    } else {
      const assignedHolidaysRef = userRef.collection('assignedHolidays');
      await assignedHolidaysRef.add({
        ...new AssignedHoliday({ timecardId, from, to, type, matchWord, isHalfDate }).toObject(),
      });
      toast.success('勤怠を登録しました');
    }
    await completionLunchTime({ date: formatDate(date, 'yyyy-MM-dd') });
  };

  return (
    <Modal isOpen={isOpen} toggle={onClickClose}>
      <ModalHeader>勤怠の編集</ModalHeader>
      <Form>
        <ModalBody>
          <FormGroup className="d-flex align-items-center">
            <Input className="mr-1" type="text" value={datetime} disabled />
            <Select
              value={
                assignedHoliday &&
                options.find((_) => _.value === (assignedHoliday.isHalfDate ? 'paid-holiday-half' : assignedHoliday.type))
              }
              onChange={onChangeValue}
              className="form-select w-100"
              options={options}
              isDisabled={!from || !to || isSyncing || isAllSyncing}
            />
          </FormGroup>
          <FormGroup>
            <Input type="text" value={title} disabled />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button className="cancel" color="secondary" onClick={onClickClose}>
            閉じる
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
