import React from 'react';
import { useToggle } from 'react-use';
import { Tooltip } from 'reactstrap';
import { subDays, addMonths } from 'date-fns';
import { eachDay } from '../../../../shared/utils/date';
import { Organization, User } from '../../../../models';
import { Timecard } from '../../../../../types';

export const WorkHourIntervalAlert = ({
  user,
  timecards,
  organization,
  startDay,
}: {
  user: User;
  timecards: Timecard[];
  organization: Organization;
  startDay: Date;
}) => {
  const [showTooltip, toggleShowTooltip] = useToggle(false);

  const hasWorkHourIntervalAlert = () => {
    return eachDay(startDay, subDays(addMonths(startDay, 1), 1)).some(
      (date) => organization && organization.hasWorkHourIntervalAlert(timecards, date)
    );
  };

  if (!hasWorkHourIntervalAlert()) return null;
  return (
    <>
      <i id={`holiday-alert-${user.id}`} className="fas fa-exclamation-triangle ml-1"></i>
      <Tooltip isOpen={showTooltip} target={`holiday-alert-${user.id}`} toggle={toggleShowTooltip}>
        勤務間インターバル（{organization.workHourInterval}h）を超過しています
      </Tooltip>
    </>
  );
};
