import React, { useContext } from 'react';
import { useToggle } from 'react-use';
import { Card, CardBody, Button, Table, Tooltip } from 'reactstrap';
import qs from 'qs';
import { format, addMonths } from 'date-fns';
import numeral from 'numeral';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import * as H from 'history';

import { buildUsage, getLateOrEarly } from '../../../utils/holidayUsage';
import { auth, functions } from '../../../firebase';
import { minToHHmmWithZero as formatTime } from '../../../utils/time';
import { AssignedHoliday, GrantedHoliday, hasLunchOvertimeAlert, Leave, Organization, Shift, WorkStyle } from '../../../models';
import { getOvertime, getLateNight } from '../../../utils/overtime';
import { getCalendar, actualWorkTime, holidayWorkTime } from '../../../utils/calendar';
import useDevice from '../../hooks/useDevice';
import { SyncContext } from '../../contexts/SyncContext';
import { PublicHoliday, Timecard, TransferHoliday, User } from '../../../../types';

const formatDay = (day: number) => numeral(day).format('0.0');
const syncAsacomData = functions.httpsCallable('syncAsacomData');

export default function Header({
  month,
  history,
  location: { search },
  timecards,
  assignedHolidays,
  grantedHolidays,
  publicHolidays,
  transferHolidays,
  leaves,
  user,
  displayUser,
  workStyle,
  organizationId,
  organization,
  shifts,
}: {
  month: Date;
  history: H.History;
  location: H.Location;
  timecards: Timecard[];
  assignedHolidays: AssignedHoliday[];
  grantedHolidays: GrantedHoliday[];
  publicHolidays: PublicHoliday[];
  transferHolidays: TransferHoliday[];
  leaves: Leave[];
  user: User;
  displayUser: User;
  workStyle: WorkStyle;
  organizationId: string;
  organization: Organization;
  shifts: Shift[];
}) {
  const [tooltipOpen, toggleTooltipOpen] = useToggle(false);
  const startDay = organization.startOfOrganizationsMonth(month);
  const { isMobile } = useDevice();
  const {
    state: { isSyncing },
    dispatch,
  } = useContext(SyncContext);

  const onClickChangeMonth = (diff: number) => {
    const queries = qs.parse(search.slice(1));
    history.replace({ search: `?${qs.stringify({ ...queries, month: format(addMonths(month, diff), 'yyyy-MM') })}` });
  };

  const usage = buildUsage(startDay, grantedHolidays, assignedHolidays);
  const calendar = getCalendar({ start: startDay, publicHolidays, transferHolidays, shifts });
  const prescribedWorkTime = workStyle?.usePersonalTimeSetting
    ? workStyle.prescribedWorkTime(startDay, publicHolidays, leaves, shifts, displayUser.workPerWeek ?? 1.0)
    : organization.prescribedWorkTime(startDay, publicHolidays, leaves, shifts, displayUser.workPerWeek ?? 1.0);
  const overtime = calendar.reduce(
    (x, y) => x + getOvertime(startDay, y.date, organization, timecards, workStyle, prescribedWorkTime),
    0
  );
  const lateNight = calendar.reduce((x, y) => x + getLateNight(y.date, organization, timecards), 0);
  const isOwnUser = user && displayUser && user.id === displayUser.id;

  const onClickResync = async () => {
    if (!user || !month || !isOwnUser || !organizationId || !window.confirm('再同期を実行します。よろしいですか？')) return;
    dispatch({ type: 'beginSync' });
    try {
      const token = await auth.currentUser!.getIdToken(true);
      await syncAsacomData({
        token,
        email: user.email,
        from: format(organization.startOfOrganizationsMonth(month), 'yyyy-MM-dd'),
        to: format(organization.endOfOrganizationsMonth(month), 'yyyy-MM-dd'),
        organizationId,
      });
      toast.success('再同期が完了しました。');
    } catch (e) {
      console.error(e);
      toast.error('再同期に失敗しました。');
    } finally {
      dispatch({ type: 'endSync' });
    }
  };

  return (
    <Card>
      <CardBody>
        <div className="d-flex justify-content-between py-3 align-items-baseline">
          <h3 className="d-flex align-items-end">
            <Button className="mr-1" color="primary" size="sm" onClick={() => onClickChangeMonth(-1)}>
              <i className="fas fa-chevron-left"></i>
            </Button>
            {format(month, 'yyyy年M月')}
            <Button className="ml-1" color="primary" size="sm" onClick={() => onClickChangeMonth(1)}>
              <i className="fas fa-chevron-right"></i>
            </Button>
            <span className="ml-2">{displayUser?.displayName}</span>
          </h3>
          <Button color="primary" onClick={onClickResync} disabled={isSyncing || !isOwnUser}>
            <i className={classnames('fas fa-sync mr-1', { 'fa-spin': isSyncing })} /> {isSyncing ? '同期中' : '再同期'}
          </Button>
        </div>
        {isMobile ? (
          <Table className="text-center">
            <tbody>
              <tr>
                <td>勤務</td>
                <td>
                  {formatTime(actualWorkTime(timecards))} /{formatTime(prescribedWorkTime)}
                  {hasLunchOvertimeAlert(timecards, organization.lunchTime) && user != null && (
                    <>
                      <i id={`lunch-overtime-alert-${user.id}`} className="fas fa-exclamation-triangle ml-2"></i>
                      <Tooltip isOpen={tooltipOpen} target={`lunch-overtime-alert-${user.id}`} toggle={toggleTooltipOpen}>
                        {organization.lunchTime}分以上のランチ・休憩があります。朝メールで他の休みに振り替えて下さい。
                      </Tooltip>
                    </>
                  )}
                </td>
              </tr>
              <tr>
                <td>休日出勤</td>
                <td>{formatTime(holidayWorkTime(calendar, timecards, organization.excessStatutoryHolidayIsWork))}</td>
              </tr>
              <tr>
                <td>残業</td>
                <td>{formatTime(overtime)}</td>
              </tr>
              <tr>
                <td>深夜勤務</td>
                <td>{formatTime(lateNight)}</td>
              </tr>
              <tr>
                <td>代休 (消化/付与)</td>
                <td>
                  {formatDay(usage.compensatoryHoliday.assign)} / {formatDay(usage.compensatoryHoliday.grant)}
                </td>
              </tr>
              <tr>
                <td>有休(時間) (消化/付与)</td>
                <td>
                  {formatTime(usage.paidHolidayTime.assign)} / {formatTime(usage.paidHolidayTime.grant)}
                </td>
              </tr>
              <tr>
                <td>有休(日) (消化/付与)</td>
                <td>
                  {formatDay(usage.paidHolidayDate.assign)} / {formatDay(usage.paidHolidayDate.grant)}
                </td>
              </tr>
              <tr>
                <td>特別休暇 (消化/付与)</td>
                <td>
                  {formatDay(usage.specialHoliday.assign)} / {formatDay(usage.specialHoliday.grant)}
                </td>
              </tr>
              <tr>
                <td>遅刻/早退</td>
                <td>{formatTime(getLateOrEarly(startDay, assignedHolidays))}</td>
              </tr>
            </tbody>
          </Table>
        ) : (
          <Table className="text-center">
            <thead>
              <tr>
                <th>勤務</th>
                <th>休日出勤</th>
                <th>残業</th>
                <th>深夜勤務</th>
                <th>
                  代休
                  <br />
                  (消化/付与)
                </th>
                <th>
                  有休(時間)
                  <br />
                  (消化/付与)
                </th>
                <th>
                  有休(日)
                  <br />
                  (消化/付与)
                </th>
                <th>
                  特別休暇
                  <br />
                  (消化/付与)
                </th>
                <th>遅刻/早退</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {formatTime(actualWorkTime(timecards))} /{formatTime(prescribedWorkTime)}
                  {hasLunchOvertimeAlert(timecards, organization.lunchTime) && user != null && (
                    <>
                      <i id={`lunch-overtime-alert-${user.id}`} className="fas fa-exclamation-triangle ml-2"></i>
                      <Tooltip isOpen={tooltipOpen} target={`lunch-overtime-alert-${user.id}`} toggle={toggleTooltipOpen}>
                        {organization.lunchTime}分以上のランチ・休憩があります。朝メールで他の休みに振り替えて下さい。
                      </Tooltip>
                    </>
                  )}
                </td>
                <td>{formatTime(holidayWorkTime(calendar, timecards, organization.excessStatutoryHolidayIsWork))}</td>
                <td>{formatTime(overtime)}</td>
                <td>{formatTime(lateNight)}</td>
                <td>
                  {formatDay(usage.compensatoryHoliday.assign)} / {formatDay(usage.compensatoryHoliday.grant)}
                </td>
                <td>
                  {formatTime(usage.paidHolidayTime.assign)} / {formatTime(usage.paidHolidayTime.grant)}
                </td>
                <td>
                  {formatDay(usage.paidHolidayDate.assign)} / {formatDay(usage.paidHolidayDate.grant)}
                </td>
                <td>
                  {formatDay(usage.specialHoliday.assign)} / {formatDay(usage.specialHoliday.grant)}
                </td>
                <td>{formatTime(getLateOrEarly(startDay, assignedHolidays))}</td>
              </tr>
            </tbody>
          </Table>
        )}
      </CardBody>
    </Card>
  );
}
