import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  InputGroup,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

import { db, FieldValue } from '../../../../firebase';

const organizationsRef = db.collection('organizations');

export default function OrganizationModal(props) {
  const { isOpen, onClickClose, organization } = props;
  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    defaultValues: {
      organization_name: organization?.name,
      organization_token: organization?.token,
      start_of_period: organization?.startOfPeriod || '04-01',
    },
  });
  const isUnsubmittable = Boolean(Object.values(errors).length);

  const onSubmit = async (values) => {
    const { organization_name: name, organization_token: token, start_of_period: startOfPeriod } = values;
    try {
      if (organization) {
        await organizationsRef
          .doc(organization.id)
          .set({ name, token, startOfPeriod, updatedAt: FieldValue.serverTimestamp() }, { merge: true });
        toast.success('組織の設定を更新しました。');
      } else {
        await organizationsRef.add({
          name,
          token,
          startOfPeriod,
          disabled: false,
          showMonthlyReport: true,
          createdAt: FieldValue.serverTimestamp(),
          updatedAt: FieldValue.serverTimestamp(),
        });
        toast.success('組織を追加しました。');
        onClickClose();
      }
    } catch (e) {
      console.error(e);
      toast.error('組織の更新に失敗しました。');
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={onClickClose}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>{organization ? `${organization.name}の設定` : '組織の追加'}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>組織名称</Label>
            <Input
              name="organization_name"
              type="text"
              valid={!Boolean(errors.organization_name)}
              invalid={Boolean(errors.organization_name)}
              innerRef={register({ required: '組織名称が未入力です' })}
            />
            {errors.organization_name && <FormFeedback>{errors.organization_name.message}</FormFeedback>}
          </FormGroup>
          <FormGroup>
            <Label>トークン</Label>
            <Input type="password" name="organization_token" innerRef={register} />
            {errors.organization_token && <FormFeedback>{errors.organization_token.message}</FormFeedback>}
          </FormGroup>
          <FormGroup>
            <Label>年度開始月日</Label>
            <InputGroup>
              <Input
                name="start_of_period"
                type="text"
                valid={!Boolean(errors.start_of_period)}
                invalid={Boolean(errors.start_of_period)}
                innerRef={register({
                  required: '年度開始月日が未入力です',
                  pattern: { value: /^[0-1][1-9]-[0-3][1-9]$/, message: 'MM-DDの形式で入力してください' },
                })}
              />
              {errors.start_of_period && <FormFeedback>{errors.start_of_period.message}</FormFeedback>}
            </InputGroup>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button className="cancel" color="secondary" onClick={onClickClose}>
            閉じる
          </Button>
          <Button color="primary" disabled={isUnsubmittable} type="submit" onClick={handleSubmit(onSubmit)}>
            {organization ? '更新' : '登録'}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
