import React from 'react';
import { useToggle } from 'react-use';
import { Card, CardBody, Button, Table } from 'reactstrap';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';
import { isEmail } from 'validator';
import { v4 as uuid } from 'uuid';

import useCollectionSubscription from '../../hooks/useCollectionSubscription';
import useDocumentSubscription from '../../hooks/useDocumentSubscription';
import useDevice from '../../hooks/useDevice';
import InvitationModal from '../../modals/InvitationModal';
import CsvUploadModal from '../../modals/CsvUploadModal';
import { db, auth, EmailAuthProvider } from '../../../firebase';

const organizationsRef = db.collection('organizations');
const invitationsRef = db.collection('invitations');

export default function Invitations(props) {
  const { organizationId } = props;
  const [showsFormModal, toggleFormModal] = useToggle();
  const [showCsvImport, toggleCsvImport] = useToggle();
  const invitations = useCollectionSubscription(
    organizationId && invitationsRef.where('organization.id', '==', organizationId),
    [organizationId]
  );
  const organization = useDocumentSubscription(organizationId && organizationsRef.doc(organizationId), [organizationId]);
  const { isMobile } = useDevice();

  const url = (token, organization) => {
    if (!organization) return;

    const { origin } = window.location;
    return `${origin}/signup?token=${token}&organizationId=${organization.id}`;
  };

  const onClickCopy = (token, organization) => {
    copy(url(token, organization));
    toast.success('URLをクリップボードにコピーしました');
  };

  const onClickDelete = (token) => {
    invitationsRef.doc(token).delete();
    toast.warn('削除しました');
  };

  const validateCsv = (rows) => {
    const errors = rows
      .map((row, i) => {
        const email = row['メールアドレス'];
        if (!email) return `${i + 1}行目：ヘッダにメールアドレスが無いか、メールアドレスが空欄です。`;
        if (!isEmail(email)) return `${i + 1}行目：メールアドレスの形式が不正です。`;
        const employeeNumber = row['社員番号'];
        if (!employeeNumber) return `${i + 1}行目：ヘッダに社員番号が無いか、社員番号が空欄、もしくは0です。`;
        if (Number.isNaN(Number(employeeNumber))) return `${i + 1}行目：不正な社員番号です。[${employeeNumber}]`;
        return null;
      })
      .filter((_) => _);
    return errors.length ? { valid: false, message: errors.join('\n') } : { valid: true };
  };

  const createInvitation = (email, employeeNumber) => {
    const token = uuid();
    return invitationsRef
      .doc(token)
      .get()
      .then(({ exists, ref }) => {
        if (exists) return this.createInvitation(email, employeeNumber);
        return ref.set({
          token,
          email,
          employeeNumber,
          organization: { id: organization.id, name: organization.name },
          role: 'member',
          createdAt: new Date(),
        });
      });
  };

  const onCsvImport = async (rows) => {
    const { valid, message } = validateCsv(rows);
    if (!valid) {
      alert(message);
      return;
    }

    for (const row of rows) {
      const email = row['メールアドレス'];
      const providers = await auth.fetchSignInMethodsForEmail(email);
      if (providers.includes(EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD)) {
        toast.error(`${email} は既に登録済みのユーザーです`);
        return;
      }
      const hasInvitation = await invitationsRef
        .where('email', '==', email)
        .get()
        .then(({ docs }) => Boolean(docs.length));
      if (hasInvitation) {
        toast.error(`${email} は既に招待済みのユーザーです`);
        return;
      }
    }

    await Promise.all(rows.map((row) => createInvitation(row['メールアドレス'], row['社員番号'])));
    toast.success('招待メールの送信を予約しました');
    toggleCsvImport(false);
  };

  const invitationMailResult = (result) => {
    if (!result) return '未送信';
    if (result === 'ok') return '送信済み';
    return <span title={result}>送信エラー</span>;
  };

  return (
    <div>
      <Card>
        <CardBody>
          <div className="d-flex justify-content-end py-3">
            <Button className="mr-1" color="primary" onClick={toggleCsvImport}>
              CSVインポート
            </Button>
            <Button color="primary" onClick={toggleFormModal}>
              <span className="fas fa-plus mr-1" />
              ユーザー招待
            </Button>
          </div>
          <Table>
            <thead>
              <tr>
                <th>メールアドレス</th>
                <th>招待メール</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {invitations.map(({ email, token, organization, result }) => (
                <tr key={token}>
                  <td className="text-nowrap">{email}</td>
                  <td className="text-nowrap">{invitationMailResult(result)}</td>
                  <td className="text-right text-nowrap">
                    <Button color="info" onClick={onClickCopy.bind(this, token, organization)}>
                      <i className="fas fa-copy"></i>
                      {isMobile ? '' : <span className="ml-1">招待URLコピー</span>}
                    </Button>
                    <Button color="danger" className="ml-2" onClick={onClickDelete.bind(this, token)}>
                      <i className="fas fa-trash"></i>
                      {isMobile ? '' : <span className="ml-1">削除</span>}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      {organization && (
        <InvitationModal isOpen={showsFormModal} onClickClose={toggleFormModal} organization={organization} role="member" />
      )}
      <CsvUploadModal
        isOpen={showCsvImport}
        onClickClose={toggleCsvImport}
        callback={onCsvImport}
        description={
          <ul>
            <li>
              ヘッダは <b>”社員番号, メールアドレス"</b> として下さい。
            </li>
            <li>
              メールアドレスは <b>"xxxxx@xxxxx.xxx"</b> の形式で指定してください。
            </li>
          </ul>
        }
      />
    </div>
  );
}
