import { Query, CollectionReference, DocumentData } from 'firebase/firestore';
import { useState, useEffect } from 'react';


export default function useCollectionSubscription<T>(
  ref: CollectionReference<DocumentData> | Query<DocumentData> | null | undefined,
  dependencies: any[] = []
): T[] {
  const [items, setItems] = useState<T[]>([]);
  useEffect(() => {
    if (!ref) return;
    // @ts-ignore v9 compactを外すまで
    const unsubscribe = ref.onSnapshot(ref, ({ docs }) => {
      setItems(docs.map((_: any) => ({ id: _.id, ref: _.ref, ..._.data() }) as unknown as T));
    });
    return unsubscribe;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
  return items;
}
