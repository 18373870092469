const { addYears, closestTo } = require('date-fns');
const { zonedTimeToUtc } = require('date-fns-tz');
const { orderBy } = require('lodash');

const parseDate = (string) => zonedTimeToUtc(string, 'Asia/Tokyo');

exports.getAssignedValue = (array, begin, end, type) => {
  return array
    .filter((_) => _.date.toDate() >= begin && _.date.toDate() <= end && _.type === type)
    .reduce((x, y) => x + y.value - (y.lunchTime || 0), 0);
};

exports.getGrantedHoliday = (grantedHolidays, assignedHolidays, lastOfAssign, lastOfGrant, type) => {
  const _grantedHolidays = grantedHolidays
    .filter((_) => _.type === type && _.date.toDate() <= lastOfGrant)
    .map((_) => ({ ..._, date: _.date.toDate(), expired: _.expired && _.expired.toDate() }));

  const _assignedHolidays = assignedHolidays
    .filter((_) => _.type === type && _.date.toDate() <= lastOfAssign)
    .map((_) => ({ ..._, date: _.date.toDate() }));

  const results = orderBy(_assignedHolidays, 'date').reduce((acc, assignedHoliday) => {
    // assign毎に対象となるgrantsを抽出
    const targetGrants = acc.filter((_) => _.value && (_.expired ? _.expired >= assignedHoliday.date : true));
    // grantsの各valueからassignのvalue分減算して、次のループにまわす
    return targetGrants.reduce(({ grants, value }, grant) => {
      if (value < 0) return { grants: [...grants, grant], value }
      const newGrant = { ...grant, value: Math.max(0, grant.value - value)};
      return { grants: [...grants, newGrant], value: value - grant.value }
    }, { grants: [], value: assignedHoliday.value - (assignedHoliday.lunchTime || 0) }).grants
  }, orderBy(_grantedHolidays, 'date'));
  return results.filter((_) => (_.expired ? _.expired > lastOfGrant : true)).reduce((x, y) => x + y.value, 0);
};

exports.closestBeforePeriod = (date) => {
  const year = date.getFullYear();
  return closestTo(
    date,
    [year, year - 1].map((y) => parseDate(`${y}-04-01`)).filter((_) => _ <= date)
  );
};

exports.getExpiredDate = (date, type) => {
  if (type === 'paid-holiday-time' || type === 'paid-holiday-date') {
    return addYears(date, 2);
  }
  if (type === 'special-holiday') {
    return addYears(date, 1);
  }
};
