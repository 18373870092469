import { subDays, addMonths } from 'date-fns';
import { getGrantedHoliday, getAssignedValue } from '../shared/utils/holiday';

export const buildUsage = (startDay, grantedHolidays, assignedHolidays) => {
  const lastDay = subDays(addMonths(startDay, 1), 1)
  return {
    paidHolidayTime: {
      grant: getGrantedHoliday(grantedHolidays, assignedHolidays, startDay, lastDay, 'paid-holiday-time'),
      assign: getAssignedValue(assignedHolidays, startDay, lastDay, 'paid-holiday-time'),
    },
    paidHolidayDate: {
      grant: getGrantedHoliday(grantedHolidays, assignedHolidays, startDay, lastDay, 'paid-holiday-date'),
      assign: getAssignedValue(assignedHolidays, startDay, lastDay, 'paid-holiday-date'),
    },
    specialHoliday: {
      grant: getGrantedHoliday(grantedHolidays, assignedHolidays, startDay, lastDay, 'special-holiday'),
      assign: getAssignedValue(assignedHolidays, startDay, lastDay, 'special-holiday'),
    },
    compensatoryHoliday: {
      grant: getGrantedHoliday(grantedHolidays, assignedHolidays, startDay, lastDay, 'compensatory-holiday'),
      assign: getAssignedValue(assignedHolidays, startDay, lastDay, 'compensatory-holiday'),
    },
  };
};

export const getLateOrEarly = (startDay, assignedHolidays) => {
  return assignedHolidays
    .filter((_) => _.date.toDate() >= startDay && _.date.toDate() < subDays(addMonths(startDay, 1), 1) && _.type === 'late-or-early')
    .reduce((x, y) => x + y.value, 0);
};
