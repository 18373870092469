import { formatDate } from '../utils/time';

export class Shift {
  constructor({ id, date, type = 'work' }) {
    this.id = id;
    this.date = date;
    this.type = type
  }

  get formattedDate() {
    return formatDate(this.date.toDate(), 'yyyy-MM-dd');
  }

  toObject() {
    return {
      date: this.date,
      type: this.type
    };
  }
}
