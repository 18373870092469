import { formatDate } from '../utils/time';
import { parseDate } from '../shared/utils/date';
import { differenceInMinutes } from 'date-fns';
import { getCalendar } from '../utils/calendar';

export class WorkStyle {
  constructor({ id, ref, from, to, usePersonalWorkTime, usePersonalFlexTime, workTimeBegin, workTimeEnd, workTimePerDay }) {
    this.id = id;
    this.ref = ref;
    this.from = from;
    this.to = to;
    this.usePersonalWorkTime = usePersonalWorkTime;
    this.usePersonalFlexTime = usePersonalFlexTime;
    this.workTimeBegin = workTimeBegin;
    this.workTimeEnd = workTimeEnd;
    this.workTimePerDay = workTimePerDay;
  }

  workTimeBeginByDate(date) {
    return parseDate(`${formatDate(date, 'yyyy-MM-dd')} ${this.workTimeBegin}`);
  }

  workTimeEndByDate(date) {
    return parseDate(`${formatDate(date, 'yyyy-MM-dd')} ${this.workTimeEnd}`);
  }

  toObject() {
    return {
      from: this.from,
      to: this.to,
      usePersonalWorkTime: this.usePersonalWorkTime,
      usePersonalFlexTime: this.usePersonalFlexTime,
      workTimeBegin: this.workTimeBegin,
      workTimeEnd: this.workTimeEnd,
      workTimePerDay: this.workTimePerDay,
    };
  }

  get workTimeMinutesPerDay() {
    if (this.usePersonalFlexTime) {
      return Number(this.workTimePerDay) * 60;
    } else {
      const begin = parseDate(`2000-01-01 ${this.workTimeBegin}`);
      const end = parseDate(`2000-01-01 ${this.workTimeEnd}`);
      const diff = differenceInMinutes(end, begin);
      if (diff < 6 * 60) {
        return diff;
      } else if (diff < 8 * 60) {
        return diff - 45;
      } else {
        return diff - 60;
      }
    }
  }

  prescribedWorkTime(startDay, publicHolidays, leaves, shifts, workPerWeek) {
    const weekdays = getCalendar({ start: startDay, publicHolidays, shifts }).filter((_) => _.type === 'weekday');
    const leaveTimeMinutes = leaves.reduce(
      (x, y) => x + y.leaveTimeOfMonth(startDay, publicHolidays, this.workTimeMinutesPerDay),
      0
    );
    return (weekdays.length * this.workTimeMinutesPerDay - leaveTimeMinutes) * workPerWeek;
  }

  get usePersonalTimeSetting() {
    return this.usePersonalWorkTime || this.usePersonalFlexTime;
  }

  get workStyleText() {
    if (this.usePersonalWorkTime) {
      return `通常 (${this.workTimeBegin}-${this.workTimeEnd})`;
    }
    if (this.usePersonalFlexTime) {
      return `フレックス (${this.workTimePerDay} 時間)`;
    }
    return '';
  }
}
