import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input, Modal, ModalBody, ModalFooter, Alert } from 'reactstrap';
import qs from 'qs';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import { auth, db, EmailAuthProvider } from '../../firebase';
import UnAuthPage from '../hocs/UnAuthPage';

const usersRef = db.collection('users');
const organizationsRef = db.collection('organizations');
const invitationsRef = db.collection('invitations');

export default UnAuthPage(function SignIn(props) {
  const [invitation, setInvitation] = useState();
  const [displayName, setDisplayName] = useState();
  const [password, setPassword] = useState();
  const [passwordConfirmation, setPasswordConfirmation] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [invalidUrl, setInvalidUrl] = useState(false);

  const isPasswordValid = password && password.length >= 6;
  const isPasswordConfimationValid = passwordConfirmation && password === passwordConfirmation;
  const isUnsubmittable = !displayName || !invitation || !isPasswordValid || !isPasswordConfimationValid;

  useEffect(() => {
    const { token } = qs.parse(window.location.search.slice(1));
    invitationsRef
      .doc(token)
      .get()
      .then((snap) => {
        if (!snap.exists) {
          toast.error('無効なURLです');
          setInvalidUrl(true);
          return;
        }
        setInvitation(snap.data());
      });
  }, []);

  const onSubmit = async (event) => {
    event.preventDefault();
    if (isUnsubmittable) return;
    const { role, email, organization, employeeNumber } = invitation;
    const { token, organizationId } = qs.parse(window.location.search.slice(1));
    try {
      const providers = await auth.fetchSignInMethodsForEmail(email);
      if (providers.includes(EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD)) throw new Error('すでに登録されています');
      if (!organization || organization.id !== organizationId) throw new Error('組織が未指定、もしくは組織IDが不正です');

      const {
        user: { uid },
      } = await auth.createUserWithEmailAndPassword(email, password);
      await Promise.all([
        // プロファイルの表示名更新
        auth.currentUser.updateProfile({ displayName }),
        // ユーザー登録
        usersRef.doc(uid).set({ email, role, displayName, organizationId, ...(employeeNumber ? { employeeNumber } : {}) }),
        organizationsRef.doc(organizationId).collection('users').doc(uid).set({ uid }),
        // 招待を無効化
        invitationsRef.doc(token).delete(),
      ]);
    } catch (e) {
      console.error(e);
      setErrorMessage(e.message);
    }
  };

  return invalidUrl || props.firebaseUser ? (
    <Redirect to="/" />
  ) : (
    <div>
      <Modal isOpen backdrop={false} keyboard={false} centered>
        <Form onSubmit={onSubmit}>
          <ModalBody>
            <Alert color="info">初回ログインのため、表示名・パスワードを設定してください。</Alert>
            <FormGroup>
              <Label for="organization">組織名</Label>
              <Input type="organization" id="organization" value={invitation?.organization?.name || ''} disabled />
            </FormGroup>
            <FormGroup>
              <Label for="email">メールアドレス</Label>
              <Input type="email" id="email" value={invitation?.email || ''} disabled />
            </FormGroup>
            <FormGroup>
              <Label for="displayName">表示名</Label>
              <Input type="text" id="displayName" onChange={(e) => setDisplayName(e.target.value)} valid={!!displayName} />
            </FormGroup>
            <FormGroup>
              <Label for="password">パスワード</Label>
              <Input
                type="password"
                id="password"
                onChange={(e) => setPassword(e.target.value)}
                valid={isPasswordValid}
                invalid={password ? !isPasswordValid : false}
              />
            </FormGroup>
            <FormGroup>
              <Label for="password_confirmation">パスワード（確認）</Label>
              <Input
                type="password"
                id="password_confirmation"
                onChange={(e) => setPasswordConfirmation(e.target.value)}
                valid={isPasswordConfimationValid}
                invalid={passwordConfirmation ? !isPasswordConfimationValid : false}
              />
            </FormGroup>
            {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit" onClick={onSubmit} disabled={isUnsubmittable}>
              登録
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
});
