import React from 'react';
import { Route, Switch } from 'react-router';

import Dashboard from './components/pages/dashboard/Dashboard';
import SignIn from './components/pages/SignIn';
import SignUp from './components/pages/SignUp';
import UserManagement from './components/pages/admin/UserManagement';
import PublicHolidaySetting from './components/pages/admin/PublicHolidaySetting';
import PeriodSetting from './components/pages/admin/periodSetting/PeriodSetting';
import OrganizationSetting from './components/pages/admin/organization/OrganizationSetting';
import ShiftSetting from './components/pages/admin/shiftSetting/ShiftSetting';
import MonthlyReport from './components/pages/monthlyReport/MonthlyReport';
import Profile from './components/pages/Profile';
import Organizations from './components/pages/sysadmin/organizations/Organizations';

const routeObjs = [
  {
    exact: true,
    path: '/',
    component: Dashboard,
  },
  {
    exact: true,
    path: '/organizations/:organizationId',
    component: Dashboard,
  },
  {
    exact: true,
    path: '/organizations/:organizationId/users/:userId',
    component: Dashboard,
  },
  {
    exact: true,
    path: '/organizations/:organizationId/admin/user-management',
    component: UserManagement,
  },
  {
    exact: true,
    path: '/organizations/:organizationId/admin/public-holiday-setting',
    component: PublicHolidaySetting,
  },
  {
    exact: true,
    path: '/organizations/:organizationId/admin/period-setting',
    component: PeriodSetting,
  },
  {
    exact: true,
    path: '/organizations/:organizationId/admin/organization-setting',
    component: OrganizationSetting,
  },
  {
    exact: true,
    path: '/organizations/:organizationId/admin/shift-setting',
    component: ShiftSetting,
  },
  {
    exact: true,
    path: '/sysadmin/organizations',
    component: Organizations,
  },
  {
    exact: true,
    path: '/organizations/:organizationId/monthly-report',
    component: MonthlyReport,
  },
  {
    exact: true,
    path: '/profile',
    component: Profile,
  },
  {
    exact: true,
    path: '/signin',
    component: SignIn,
  },
  {
    exact: true,
    path: '/signup',
    component: SignUp,
  },
];

export default function routes(extraProps) {
  return (
    <Switch>
      {routeObjs.map((route, i) => {
        const { exact, path, render, props } = route;
        return (
          <Route
            exact={exact}
            path={path}
            render={render || ((_) => <route.component {..._} {...extraProps} {...props} />)}
            key={i}
          />
        );
      })}
    </Switch>
  );
}
