import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, FormGroup, Input, Label } from 'reactstrap';
import { parseDate } from '../../utils/time';

export default function PublicHolidaySettingModal(props) {
  const { isOpen, onClickClose, values } = props;
  const [name, setName] = useState('');
  const [date, setDate] = useState('');
  const isInvalidName = !name;
  const isInvalidDate = !date;
  const isUnsubmittable = isInvalidName || isInvalidDate;

  const isNew = !values;
  useEffect(() => {
    values && setName(values.name);
    values && setDate(values.date);
  }, [values])

  const onSubmit = async (event) => {
    event.preventDefault();
    if (isUnsubmittable) return;
    props.onSubmit({ name, date: parseDate(date) });
    setName('');
    setDate('');
  };

  return (
    <Modal isOpen={isOpen} toggle={onClickClose}>
      <ModalHeader>祝日の{isNew ? '登録' : '編集'}</ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          <FormGroup>
            <Label for="name">名前</Label>
            <Input
              value={name}
              valid={!isInvalidName}
              invalid={name ? isInvalidName : false}
              type="name"
              id="name"
              onChange={(e) => setName(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="date">日付</Label>
            <Input
              value={date}
              valid={!isInvalidDate}
              invalid={date ? isInvalidDate : false}
              type="date"
              id="date"
              placeholder="yyyy/mm/dd"
              onChange={(e) => setDate(e.target.value)}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button className="cancel" color="secondary" onClick={onClickClose}>
            閉じる
          </Button>
          <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable}>
            保存
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
