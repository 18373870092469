import React, { useState } from 'react';
import { Card, CardBody, Table, Button } from 'reactstrap';
import qs from 'qs';
import { addMonths } from 'date-fns';
import Select from 'react-select';
import { useToggle } from 'react-use';
import { uniqBy } from 'lodash';

import Page from '../../../hocs/Page';
import useDocumentSubscription from '../../../hooks/useDocumentSubscription';
import useUsersSubscription from '../../../hooks/useUsersSubscription';
import useDevice from '../../../hooks/useDevice';
import { db } from '../../../../firebase';
import { monthFromSearch } from '../../../../utils/calendar';
import { formatDate } from '../../../../utils/time';
import ShiftSettingRow from './ShiftSettingRow';
import { Organization } from '../../../../models';

const organizationsRef = db.collection('organizations');

export default Page(function ShiftSetting(props) {
  const {
    history,
    location: { search },
    organizationId,
  } = props;
  const month = monthFromSearch(search);
  const organization = new Organization(useDocumentSubscription(organizationsRef.doc(organizationId), [organizationId]) || {});
  const users = useUsersSubscription(organizationId);
  const [searchValue, setSearchValue] = useState(null);
  const [isSearching, toggleSearching] = useToggle(false);
  const filterdUsers = users.filter((_) => (!searchValue || _.group === searchValue.value));
  const { isMobile } = useDevice();

  const onClickChangeMonth = (diff, event) => {
    const queries = qs.parse(search.slice(1));
    history.replace({ search: `?${qs.stringify({ ...queries, month: formatDate(addMonths(month, diff), 'yyyy-MM') })}` });
  };

  return (
    <div className="container p-5 position-relative">
      <Card className="my-5">
        <CardBody>
          <div className="d-flex justify-content-between py-3 align-items-baseline">
            <h3 className="d-flex align-items-end">
              <Button className="mr-1" color="primary" size="sm" onClick={onClickChangeMonth.bind(this, -1)}>
                <i className="fas fa-chevron-left"></i>
              </Button>
              {formatDate(month, 'yyyy年M月')}
              <Button className="ml-1" color="primary" size="sm" onClick={onClickChangeMonth.bind(this, 1)}>
                <i className="fas fa-chevron-right"></i>
              </Button>
            </h3>
          </div>
          <div className="d-flex justify-content-center">
            <h4>シフト設定</h4>
          </div>
          <div className="d-flex justify-content-end py-3">
            <Select
              value={searchValue}
              onChange={(option) => setSearchValue(option)}
              className={`form-select mr-1 ${isMobile ? 'w-100' : 'w-25'}`}
              options={uniqBy(users.filter((_) => _.group).map((_) => ({ label: _.group, value: _.group })), 'value')}
              onFocus={() => toggleSearching(true)}
              onBlur={() => toggleSearching(false)}
              isClearable
              placeholder="グループで絞り込み..."
            />
          </div>
          <Table className={!isSearching ? 'sticky-table' : ''}>
            <thead>
              <tr>
                <th>名前</th>
                <th>グループ</th>
                <th>勤務日数</th>
                <th>休日数</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filterdUsers.filter(_ => _.useShift).map((user) => (
                <ShiftSettingRow
                  key={user.id}
                  user={user}
                  month={month}
                  organization={organization}
                />
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
});
