import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Navbar, Nav, NavbarText, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { auth, db } from '../firebase';
import useDocumentSubscription from './hooks/useDocumentSubscription';

const organizationsRef = db.collection('organizations');

export default function HeaderNav(props) {
  const { user, organizationId } = props;
  const organization = useDocumentSubscription(organizationId && organizationsRef.doc(organizationId), [organizationId]);
  const logout = () => {
    toast.success('ログアウトしました');
    auth.signOut();
  };
  return (
    <header className="header-nav">
      <Navbar dark className="justify-content-between" style={{ backgroundColor: '#ec6b00' }}>
        <>
          {user ? (
            <Link to={`/organizations/${organizationId}/users/${user.id}`} className="navbar-brand">
              朝メール勤怠
            </Link>
          ) : (
            <span className="navbar-brand">朝メール勤怠</span>
          )}
          <NavbarText className="mr-auto">{organization?.name}</NavbarText>
          {user && (
            <Nav navbar className="header-nav__user-menu">
              <UncontrolledDropdown nav tag="div">
                <DropdownToggle nav caret>
                  {user.displayName || user.email}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem tag={Link} to="/profile">
                    プロフィール
                  </DropdownItem>
                  {(user.isAdmin || user.isSysAdmin || organization?.showMonthlyReport) && (
                    <DropdownItem tag={Link} to={`/organizations/${organizationId}/monthly-report`}>
                      月間集計
                    </DropdownItem>
                  )}
                  <DropdownItem divider />
                  {(user.isAdmin || user.isSysAdmin) && (
                    <Fragment>
                      <DropdownItem header>管理機能</DropdownItem>
                      <DropdownItem tag={Link} to={`/organizations/${organizationId}/admin/period-setting`}>
                        休暇設定
                      </DropdownItem>
                      <DropdownItem tag={Link} to={`/organizations/${organizationId}/admin/public-holiday-setting`}>
                        祝日設定
                      </DropdownItem>
                      <DropdownItem tag={Link} to={`/organizations/${organizationId}/admin/shift-setting`}>
                        シフト設定
                      </DropdownItem>
                      <DropdownItem tag={Link} to={`/organizations/${organizationId}/admin/user-management`}>
                        ユーザー管理
                      </DropdownItem>
                      <DropdownItem tag={Link} to={`/organizations/${organizationId}/admin/organization-setting`}>
                        組織設定
                      </DropdownItem>
                      <DropdownItem divider />
                    </Fragment>
                  )}
                  {user.isSysAdmin && (
                    <Fragment>
                      <DropdownItem header>システム管理機能</DropdownItem>
                      <DropdownItem tag={Link} to="/sysadmin/organizations">
                        組織管理
                      </DropdownItem>
                      <DropdownItem divider />
                    </Fragment>
                  )}
                  <DropdownItem onClick={logout}>ログアウト</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          )}
        </>
      </Navbar>
    </header>
  );
}
