import React from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, Table } from 'reactstrap';
import { toast } from 'react-toastify';
import { useForm, Controller } from 'react-hook-form';
import { format as formatDate, isEqual } from 'date-fns';
import ToggleButton from 'react-toggle-button';
import { isEmpty } from 'lodash';

import { db } from '../../firebase';
import { eachDay, parseDate } from '../../shared/utils/date';

const organizationsRef = db.collection('organizations');

export default function ShiftSettingModal(props) {
  const { isOpen, onClose, organization, user, month, from, to, shifts } = props;
  const shiftsRef = organizationsRef.doc(organization.id).collection('users').doc(user.id).collection('shifts');
  const { handleSubmit, errors, control, watch } = useForm();
  const isUnsubmittable = Boolean(Object.values(errors).length);
  const watchFields = watch();

  const onSubmit = async (values) => {
    try {
      await Promise.all(
        Object.entries(values).map(([key, value]) => {
          const shift = shifts.find((_) => isEqual(_.date.toDate(), parseDate(key)));
          const type = value ? 'holiday' : 'work';
          if (shift) {
            return shiftsRef.doc(shift.id).set({ type }, { merge: true });
          } else {
            return shiftsRef.add({ date: parseDate(key), type });
          }
        })
      );
      toast.success('シフトを更新しました。');
      onClose();
    } catch (e) {
      console.error(e);
      toast.error('組織の更新に失敗しました。');
    }
  };

  const workCount = isEmpty(watchFields)
    ? shifts.filter((_) => _.type === 'work').length
    : Object.values(watchFields).filter((_) => !_).length;
  const holidayCount = isEmpty(watchFields)
    ? shifts.filter((_) => _.type === 'holiday').length
    : Object.values(watchFields).filter((_) => _).length;

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>{`${formatDate(month, 'yyyy年M月')}のシフト（${user.displayName}）`}</ModalHeader>
        <ModalBody>
          <Table>
            <thead>
              <tr>
                <th>日付</th>
                <th>休日設定</th>
              </tr>
            </thead>
            <tbody>
              {shifts &&
                eachDay(from, to).map((date) => {
                  const formattedDate = formatDate(date, 'yyyy-MM-dd');
                  const defaultValue = shifts.find((_) => isEqual(_.date.toDate(), date))?.type === 'holiday';
                  // watchFieldsにキーがない場合のみdefautValueを参照する
                  const isHoliday = isEmpty(watchFields) ? defaultValue : watchFields[formattedDate];
                  return (
                    <tr key={formattedDate} style={{ backgroundColor: isHoliday ? 'pink' : '' }}>
                      <td>{formatDate(date, 'yyyy-MM-dd')}</td>
                      <td>
                        <Controller
                          name={formattedDate}
                          control={control}
                          defaultValue={defaultValue}
                          render={(props) => (
                            <ToggleButton
                              inactiveLabel=""
                              activeLabel="○"
                              value={props.value}
                              onToggle={(value) => props.onChange(!value)}
                            />
                          )}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          <Table>
            <tbody>
              <tr>
                <td>勤務日数：{workCount}</td>
                <td>休日数：{holidayCount}</td>
              </tr>
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button className="cancel" color="secondary" onClick={onClose}>
            閉じる
          </Button>
          <Button color="primary" disabled={isUnsubmittable} type="submit" onClick={handleSubmit(onSubmit)}>
            更新
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
