/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { User } from '../../models';
import useCollectionSubscription from './useCollectionSubscription';
import { orderBy } from 'lodash';

const organizationsRef = db.collection('organizations');
const usersRef = db.collection('users');

export default function useUsersSubscription(organizationId: string) {
  const users: { uid: string}[] = useCollectionSubscription(
    organizationsRef.doc(organizationId || "").collection('users') as any,
    [organizationId]
  );
  const [items, setItems] = useState<User[]>([]);
  useEffect(() => {
    const unsubscribes = users.map(({ uid }) => {
      return usersRef.doc(uid).onSnapshot((doc) => {
        if (doc.exists) {
          const user = new User({ id: doc.id, ref: doc.ref, ...doc.data() });
          setItems((prevItems) => [...prevItems.filter((_) => _.id !== doc.id), user]);
        } else {
          setItems((prevItems) => [...prevItems.filter((_) => _.id !== doc.id)]);
        }
      });
    });
    return () => unsubscribes.forEach((unsubscribe) => unsubscribe());
  }, [users]);
  return orderBy(items, ['employeeNumber', 'displayName']);
}
