import { holidayTypes } from '../shared/config';
import { formatDate, minToHHmm } from '../utils/time';

export class GrantedHoliday {
  constructor({ id, date, expired, value, type }) {
    this.id = id;
    this.date = date;
    this.expired = expired;
    this.value = value;
    this.type = type;
  }

  get holidayType() {
    return holidayTypes.find((_) => _.type === this.type) || {};
  }

  get isTime() {
    return this.holidayType.format === 'time';
  }

  get formattedDate() {
    return formatDate(this.date.toDate(), 'yyyy-MM-dd');
  }

  get formattedExpired() {
    return this.expired ? formatDate(this.expired.toDate(), 'yyyy-MM-dd') : '';
  }

  get formattedValue() {
    return this.isTime ? minToHHmm(this.value) : this.value;
  }

  toObject() {
    return {
      date: this.date,
      expired: this.expired,
      value: this.value,
      type: this.type,
    };
  }
}
