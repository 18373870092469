exports.validate = (timecards, timecard) => {
  const lunch = timecards.find((_) => _.type === 'lunch');
  if (!lunch) return false;
  if (lunch.from.toDate() > timecard.from.toDate()) {    
    // 対象がAM: ランチ以前のバーが1本の場合のみ半休を許可
    return timecards.filter((_) => _.from.toDate() < lunch.from.toDate()).length === 1;
  } else {
    // 対象がPM: ランチ以後のバーが1本の場合のみ半休を許可
    return timecards.filter((_) => _.from.toDate() > lunch.from.toDate()).length === 1;
  }
};
