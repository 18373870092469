import React from 'react';
import { useToggle } from 'react-use';
import { Tooltip } from 'reactstrap';
import { isEqual } from 'date-fns';
import { User } from '../../../../models';
import { Timecard } from '../../../../../types';

export const HolidayAlert = ({
  user,
  timecards,
  calendar,
}: {
  user: User;
  timecards: Timecard[];
  calendar: { date: Date; type: string }[];
}) => {
  const [showTooltip, toggleShowTooltip] = useToggle(false);

  const hasHolidayAlert = () => {
    // 法定休日（or 所定休日）に「休み」が入っている場合アラート
    return (
      calendar.filter(({ date, type }) => {
        return type !== 'weekday' && timecards.filter((_) => isEqual(_.date.toDate(), date)).some((_) => _.type === 'holiday');
      }).length !== 0
    );
  };

  if (!hasHolidayAlert()) return null;
  return (
    <>
      <i id={`holiday-alert-${user.id}`} className="fas fa-exclamation-triangle ml-2"></i>
      <Tooltip isOpen={showTooltip} target={`holiday-alert-${user.id}`} toggle={toggleShowTooltip}>
        休日に「休み」を取得しています。
      </Tooltip>
    </>
  );
};
