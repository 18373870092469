import { formatDate } from '../utils/time';
import { eachDay } from '../shared/utils/date';
import { eachDayOfInterval, subDays, endOfDay, addMonths, differenceInDays } from 'date-fns';
import { getWeekdays } from '../utils/calendar';

export class Leave {
  constructor({ id, period, reason, begin, end }) {
    this.id = id;
    this.period = period;
    this.reason = reason;
    this.begin = begin;
    this.end = end;
  }

  get formattedBegin() {
    return formatDate(this.begin.toDate(), 'yyyy-MM-dd');
  }

  get formattedEnd() {
    return formatDate(this.end.toDate(), 'yyyy-MM-dd');
  }

  toObject() {
    return {
      period: this.period,
      reason: this.reason,
      begin: this.begin,
      end: this.end,
    };
  }

  toBarChartItems = (startDay, publicHolidays) => {
    const range = eachDay(this.begin.toDate(), this.end.toDate()).filter(
      (_) => _ >= startDay && _ <= subDays(addMonths(startDay, 1), 1)
    );
    return getWeekdays(range, publicHolidays).map((_) => {
      const day = differenceInDays(_, startDay);
      return {
        className: 'leave',
        start: subDays(_, day),
        end: subDays(endOfDay(_), day),
        date: _,
        from: _,
        to: endOfDay(_),
        title: this.reason,
        group: _.getTime(),
      };
    });
  };

  leaveTimeOfMonth = (startDay, publicHolidays, workTimePerDay) => {
    const range = eachDayOfInterval({ start: this.begin.toDate(), end: this.end.toDate() }).filter(
      (_) => _ >= startDay && _ < subDays(addMonths(startDay, 1), 1)
    );
    return getWeekdays(range, publicHolidays).length * workTimePerDay;
  };
}
