import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, FormGroup, Input, Table } from 'reactstrap';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { holidayTypes, paidHolidayTypes } from '../../shared/config';
import { parseDate } from '../../utils/time';
import { getExpiredDate } from '../../shared/utils/holiday';

const options = holidayTypes.map(({ label, type }) => ({ label, value: type }));

export default function HolidayGrantModal(props) {
  const { userRef, isOpen, onClose, items = [], periodRange } = props;
  const [formDate, setFormDate] = useState('');
  const [formType, setFormType] = useState('');
  const [formValue, setFormValue] = useState(0);

  const isUnsubmittable = !formDate || !formType || !formValue;

  const onSubmit = async () => {
    if (isUnsubmittable) return;

    const selectedType = paidHolidayTypes.find((_) => _.type === formType);
    const [month] = formDate.match(/\d{4}-\d{2}/);
    const expired = getExpiredDate(parseDate(formDate), formType) || null;
    const grantedHolidaysRef = userRef.collection('grantedHolidays');
    await grantedHolidaysRef.add({
      month: parseDate(month),
      date: parseDate(formDate),
      type: formType,
      value: selectedType.format === 'time' ? Number(formValue) * 60 : Number(formValue),
      expired,
    });
    toast.success('追加しました。');
    setFormDate('');
    setFormType('');
    setFormValue(0);
  };

  const onDelete = async (item) => {
    if (!window.confirm('削除します。よろしいですか？')) return;

    const grantedHolidaysRef = userRef.collection('grantedHolidays');
    await grantedHolidaysRef.doc(item.id).delete();
    toast.warn('削除しました。');
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose} size="lg">
      <ModalHeader>休暇の付与</ModalHeader>
      <ModalBody>
        <FormGroup className="d-flex align-items-center">
          <Input
            className="mr-1"
            type="date"
            value={formDate}
            onChange={({ target: { value } }) => setFormDate(value)}
            min={periodRange.begin}
            max={periodRange.end}
          />
          <Select
            value={options.find((_) => _.value === formType)}
            onChange={({ value }) => setFormType(value)}
            className="form-select w-100 mr-1"
            options={options}
          />
          <Input
            className="mr-1"
            type="number"
            value={formValue}
            onChange={({ target: { value } }) => setFormValue(value)}
          />
          <Button size="sm" color="primary" onClick={onSubmit} disabled={isUnsubmittable}>
            <i className="fas fa-plus"></i>
          </Button>
        </FormGroup>

        <Table>
          <thead>
            <tr>
              <th>付与日</th>
              <th>種別</th>
              <th>時間/日数</th>
              <th>失効日</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {items.map((item) => {
              const type = paidHolidayTypes.find((_) => _.type === item.type) || {};
              return (
                <tr key={item.id}>
                  <td>{item.formattedDate}</td>
                  <td>{type.label}</td>
                  <td>{item.formattedValue}</td>
                  <td>{item.formattedExpired}</td>
                  <td>
                    <Button color="danger" size="sm" onClick={onDelete.bind(this, item)}>
                      <i className="fas fa-trash"></i>
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </ModalBody>
      <LeavesSetting {...props} />
      <ModalFooter>
        <Button className="cancel" color="secondary" onClick={onClose}>
          閉じる
        </Button>
      </ModalFooter>
    </Modal>
  );
}

function LeavesSetting(props) {
  const { userRef, leaves = [], periodRange } = props;
  const [formReason, setFormReason] = useState('');
  const [formBegin, setFormBegin] = useState('');
  const [formEnd, setFormEnd] = useState('');

  const isUnsubmittable = !formReason || !formBegin || !formEnd;

  const onSubmit = async () => {
    if (isUnsubmittable) return;

    const [period] = periodRange.begin.split('-');
    const leavesRef = userRef.collection('leaves');
    await leavesRef.add({
      period: Number(period),
      reason: formReason,
      begin: parseDate(formBegin),
      end: parseDate(formEnd),
    });
    toast.success('追加しました。');
    setFormReason('');
    setFormBegin('');
    setFormEnd('');
  };

  const onDelete = async (item) => {
    if (!window.confirm('削除します。よろしいですか？')) return;
    const leavesRef = userRef.collection('leaves');
    await leavesRef.doc(item.id).delete();
    toast.warn('削除しました。');
  };

  return (
    <>
      <ModalHeader>休業設定</ModalHeader>
      <ModalBody>
        <FormGroup className="d-flex align-items-center">
          <Input
            className="mr-1"
            value={formReason}
            onChange={({ target: { value } }) => setFormReason(value)}
            placeholder="（休業の理由）"
          />
          <Input
            className="mr-1"
            type="date"
            value={formBegin}
            onChange={({ target: { value } }) => setFormBegin(value)}
            min={periodRange.begin}
            max={periodRange.end}
          />
          　〜　
          <Input
            className="mr-1"
            type="date"
            value={formEnd}
            onChange={({ target: { value } }) => setFormEnd(value)}
            min={periodRange.begin}
            max={periodRange.end}
          />
          <Button size="sm" color="primary" onClick={onSubmit} disabled={isUnsubmittable}>
            <i className="fas fa-plus"></i>
          </Button>
        </FormGroup>
        <Table>
          <thead>
            <tr>
              <th>休業の理由</th>
              <th>開始</th>
              <th>終了</th>
            </tr>
          </thead>
          <tbody>
            {leaves.map((item) => {
              return (
                <tr key={item.id}>
                  <td>{item.reason}</td>
                  <td>{item.formattedBegin}</td>
                  <td>{item.formattedEnd}</td>
                  <td>
                    <Button color="danger" size="sm" onClick={onDelete.bind(this, item)}>
                      <i className="fas fa-trash"></i>
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </ModalBody>
    </>
  );
}
