import React from 'react';
import { Navbar, Nav } from 'reactstrap';
import { useTitle } from 'react-use';

export default function UnAuthPageHOC(WrappedComponent) {
  return function UnAuthPage(props) {
    useTitle('朝メール勤怠');
    return (
      <div className="un-auth-page">
        <header className="header-nav">
          <Navbar dark className="justify-content-between" style={{ backgroundColor: '#ec6b00' }}>
            <Nav className="navbar-brand">朝メール勤怠</Nav>
          </Navbar>
        </header>
        <WrappedComponent {...props} />
      </div>
    );
  };
}
