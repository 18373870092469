import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, FormGroup, Input } from 'reactstrap';
import { parse as parseCsv } from 'papaparse';
import encoding from 'encoding-japanese';

const getFileContents = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = function (e) {
      const codes = new Uint8Array(e.target.result);
      const result = encoding.detect(codes);
      // Convert encoding to unicode
      const unicodeString = encoding.convert(codes, {
        to: 'unicode',
        // NOTE: detect がWindows31-Jを判定できない（UNICODEになる）のでその場合はSJISとして処理する
        from: result === 'UNICODE' ? 'SJIS' : result,
        type: 'string',
      });
      resolve(unicodeString);
    };
    reader.readAsArrayBuffer(file);
  });
};

export default function CsvUploadModal(props) {
  const { isOpen, description, callback } = props;
  const [file, setFile] = useState('');
  const isUnsubmittable = !file;

  const onSubmit = async (event) => {
    event.preventDefault();

    const contents = await getFileContents(file);
    const { data, errors } = parseCsv(contents, {
      header: true,
      delimiter: ',',
      skipEmptyLines: true,
      transformHeader: (value) => value.trim(),
      transform: (value) => value.trim(),
    });
    if (errors.length) {
      const message = errors
        .map(({ code, row, message }) => {
          if (code === 'UndetectableDelimiter') return `${row + 1}行目：区切り文字を識別できません。`;
          if (code === 'TooFewFields') return `${row + 1}行目：ヘッダよりフィールドの数が少ないです。`;
          if (code === 'TooManyFields') return `${row + 1}行目：ヘッダよりフィールドの数が多いです。`;
          return `${row + 1}行目：[${code}] ${message}`;
        })
        .join('\n');
      alert(message);
    } else if (!data.length) {
      alert('取り込みデータが0件です。');
    } else {
      callback(data);
    }
  };

  const onClickClose = () => {
    setFile('');
    props.onClickClose();
  };

  return (
    <Modal isOpen={isOpen} toggle={onClickClose}>
      <ModalHeader>CSVインポート</ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          {description}
          <FormGroup>
            <Input
              valid={!isUnsubmittable}
              invalid={file ? isUnsubmittable : false}
              type="file"
              onChange={(e) => setFile(e.target.files[0])}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button className="cancel" color="secondary" onClick={onClickClose}>
            閉じる
          </Button>
          <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable}>
            アップロード
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
