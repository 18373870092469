import React, { Fragment, useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useTitle } from 'react-use';

import HeaderNav from '../HeaderNav';
import { User } from '../../models/user';
import { db, auth } from '../../firebase';
import useDocumentSubscription from '../hooks/useDocumentSubscription';

const usersRef = db.collection('users');

export default function PageHOC(WrappedComponent) {
  return function Page(props) {
    const {
      match: {
        params: { organizationId: _orgId },
      },
    } = props;
    const firebaseUser = props.firebaseUser;
    const user = new User(useDocumentSubscription(firebaseUser && usersRef.doc(firebaseUser.uid), [firebaseUser]) || {});
    const organizationId = _orgId || (user && user.organizationId);

    const [loggedIn, setLoggedIn] = useState(true);
    useTitle('朝メール勤怠');
    useEffect(() => {
      return auth.onAuthStateChanged((firebaseUser) => {
        setLoggedIn(firebaseUser);
      });
    }, []);

    return (
      <Fragment>
        {!loggedIn && <Redirect to="/signin" />}
        <div className="page">
          <HeaderNav {...props} {...{ user, organizationId }} />
          <WrappedComponent {...props} {...{ user, organizationId }} />
        </div>
      </Fragment>
    );
  };
}
