import React from 'react';
import { useToggle } from 'react-use';
import { Tooltip } from 'reactstrap';
import { subDays, addMonths } from 'date-fns';
import { User } from '../../../../models';
import { AssignedHoliday } from '../../../../../types';

export const UnpaidLeaveAlert = ({
  user,
  startDay,
  assignedHolidays,
}: {
  user: User;
  startDay: Date;
  assignedHolidays: AssignedHoliday[];
}) => {
  const [showTooltip, toggleShowTooltip] = useToggle(false);

  const hasUnpaidLeaveAlert = () => {
    // 無給休暇を取得している場合アラート
    return assignedHolidays.some(
      (_) => _.date.toDate() >= startDay && _.date.toDate() < subDays(addMonths(startDay, 1), 1) && _.type === 'unpaid-leave'
    );
  };

  if (!hasUnpaidLeaveAlert()) return null;
  return (
    <>
      <i id={`unpaid-leave-alert-${user.id}`} className="fas fa-exclamation-triangle ml-2"></i>
      <Tooltip isOpen={showTooltip} target={`unpaid-leave-alert-${user.id}`} toggle={toggleShowTooltip}>
        無給休暇を取得しています。
      </Tooltip>
    </>
  );
};
