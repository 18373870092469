import React, { useState, useEffect, useRef, Fragment } from 'react';
import Timeline from 'react-visjs-timeline';
import { Card, CardBody } from 'reactstrap';
import { useToggle } from 'react-use';
import { startOfDay, endOfDay, addHours, subDays, differenceInDays } from 'date-fns';

import HolidayAssignModal from '../../modals/HolidayAssignModal';
import { getCalendar } from '../../../utils/calendar';
import { formatDate } from '../../../utils/time';
import { Organization, Leave, Shift } from '../../../models';
import { Timecard, AssignedHoliday, PublicHoliday, TransferHoliday, BarChartItem } from '../../../../types';

type Props = {
  month: Date;
  organization: Organization;
  timecards: Timecard[];
  assignedHolidays: AssignedHoliday[];
  publicHolidays: PublicHoliday[];
  leaves: Leave[];
  transferHolidays: TransferHoliday[];
  shifts: Shift[];
};

const useItems = (
  startDay: Date,
  timecards: Timecard[],
  assignedHolidays: AssignedHoliday[],
  leaves: BarChartItem[]
): BarChartItem[] => {
  const items = timecards.map(({ id, date, from, to, type, note }) => {
    const day = differenceInDays(from.toDate(), startDay);
    const { type: className } =
      assignedHolidays.find((_) => _.from.seconds === from.seconds && _.to.seconds === to.seconds && !_.hidden) || {};
    return {
      timecardId: id,
      className: className || type,
      start: subDays(from.toDate(), day),
      end: subDays(to.toDate(), day),
      date: date.toDate(),
      from,
      to,
      title: note,
      group: date.toDate().getTime(),
    };
  });
  const isInTimecards = (leave: BarChartItem) => items.some((item) => item.group === leave.group);
  const leaveItems = leaves.filter((leave) => !isInTimecards(leave));
  return [...items, ...leaveItems];
};

export default function BarChart(props: Props) {
  const { month, timecards, assignedHolidays, publicHolidays, leaves, transferHolidays, organization, shifts } = props;
  const [showsFormModal, toggleFormModal] = useToggle(false);
  const [item, setItem] = useState<BarChartItem>();
  const startDay = organization.startOfOrganizationsMonth(month);

  const ref = useRef<BarChartItem[]>([]);
  const items = useItems(
    startDay,
    timecards,
    assignedHolidays,
    leaves.map((_) => _.toBarChartItems(startDay, publicHolidays)).flat()
  );
  useEffect(() => {
    ref.current = items;
  });

  const timelineOptions = {
    width: '100%',
    stack: false,
    showMajorLabels: false,
    showCurrentTime: false,
    type: 'range',
    min: startOfDay(startDay),
    max: endOfDay(startDay),
    start: addHours(startDay, 7),
    end: addHours(startDay, 19),
    zoomable: false,
    timeAxis: { scale: 'hour', step: 1 },
    orientation: { axis: 'both' },
    groupOrder: 'id',
  };

  const timelineGroups = getCalendar({ start: startDay, publicHolidays, transferHolidays, shifts }).map(({ date, type }) => {
    return {
      id: date.getTime(),
      content: `<span class="${type}">${formatDate(date, 'yyyy/MM/dd(E)')}</span>`,
    };
  });

  const onClickItem = ({ group: g, time }: { group: number; time: Date }) => {
    // NOTE: callbackの内部で使うprops,stateはrendar時に固定されてしまうため,useRefで常に最新の参照を取得する
    // https://stackoverflow.com/questions/55154186/react-hooks-usestateuseeffectevent-gives-stale-state/55156813#55156813
    const item = ref.current.find(({ group, start, end }) => group === g && start <= time && end >= time);
    if (!item || ['work', 'lunch', 'leave'].includes(item.className)) return;
    setItem(item);
    toggleFormModal();
  };

  return (
    <Fragment>
      <Card>
        <CardBody>
          <Timeline options={timelineOptions} groups={timelineGroups} items={items} clickHandler={onClickItem} />
        </CardBody>
      </Card>
      <HolidayAssignModal
        {...props}
        item={item}
        items={items}
        isOpen={showsFormModal}
        onClickClose={toggleFormModal}
        useHalfPaidHoliday={organization.useHalfPaidHoliday}
      />
    </Fragment>
  );
}
