import React from 'react';
import { useToggle } from 'react-use';
import numeral from 'numeral';
import { isEqual, isWithinInterval } from 'date-fns';
import { Tooltip } from 'reactstrap';
import { groupBy } from 'lodash';
import firebase from 'firebase/compat/app';

import useCollectionSubscription from '../../hooks/useCollectionSubscription';
import { Timestamp } from '../../../firebase';
import { buildUsage, getLateOrEarly } from '../../../utils/holidayUsage';
import { minToHHmmWithZero as formatTime, formatDate } from '../../../utils/time';
import {
  getCalendar,
  totalWorkTime,
  actualWorkTime,
  legalHolidayWorkTime,
  regularHolidayWorkTime,
} from '../../../utils/calendar';
import { getOvertime, getLateNight } from '../../../utils/overtime';
import { Leave, WorkStyle, Shift, User, Organization, GrantedHoliday } from '../../../models';
import { LunchOvertimeAlert, HolidayAlert, UnpaidLeaveAlert, WorkHourIntervalAlert } from './alerts';
import { AssignedHoliday, PublicHoliday, Timecard, TransferHoliday } from '../../../../types';

const formatDay = (day: number) => numeral(day).format('0.0');

export default function MonthlyReportRow({
  user,
  userRef,
  search,
  month,
  publicHolidays,
  organization,
  isMobile,
}: {
  user: User;
  userRef: firebase.firestore.DocumentReference;
  search: string;
  month: Date;
  publicHolidays: PublicHoliday[];
  organization: Organization;
  isMobile: boolean;
}) {
  const startDay = organization.startOfOrganizationsMonth(month);
  const workStyles = useCollectionSubscription<WorkStyle>(userRef && userRef.collection('workStyles') as any, [user, organization]).map(
    (_) => new WorkStyle(_)
  );
  const workStyle = workStyles.find(({ from, to }) => isWithinInterval(startDay, { start: from.toDate(), end: to.toDate() }));
  const timecards = useCollectionSubscription<Timecard>(
    userRef &&
      userRef
        .collection('timecards')
        .orderBy('date')
        .startAt(Timestamp.fromDate(organization.startOfOrganizationsMonth(month)))
        .endAt(Timestamp.fromDate(organization.endOfOrganizationsMonth(month))) as any,
    [user, search, organization]
  );
  const assignedHolidays = useCollectionSubscription<AssignedHoliday>(userRef.collection('assignedHolidays') as any, [user]);
  const grantedHolidays = useCollectionSubscription<GrantedHoliday>(userRef.collection('grantedHolidays') as any, [user]);
  const transferHolidays = useCollectionSubscription<TransferHoliday>(userRef.collection('transferHolidays') as any, [user]);
  const leaves = useCollectionSubscription<Leave>(userRef && userRef.collection('leaves') as any, [user]).map((_) => new Leave(_));
  const shifts = useCollectionSubscription<Shift>(user.useShift && userRef && userRef.collection('shifts') as any, [user]).map(
    (_) => new Shift(_)
  );
  const prescribedWorkTime = workStyle?.usePersonalTimeSetting
    ? workStyle.prescribedWorkTime(startDay, publicHolidays, leaves, shifts, user.workPerWeek)
    : organization.prescribedWorkTime(startDay, publicHolidays, leaves, shifts, user.workPerWeek);
  const usage = buildUsage(startDay, grantedHolidays, assignedHolidays);
  const [workTimePerTooltipOpen, toggleWorkTimePerTooltipOpen] = useToggle(false);
  const calendar = getCalendar({ start: startDay, publicHolidays, transferHolidays, shifts });

  const workCount = () =>
    calendar.filter(({ date }) => timecards.filter((_) => isEqual(_.date.toDate(), date)).some((_) => _.type === 'work'))
      .length;
  const holidayWorkCount = () =>
    calendar.filter(({ date, type }) => {
      const isHoliday = organization.excessStatutoryHolidayIsWork ? type === 'legal-holiday' : type.includes('holiday');
      return isHoliday && timecards.filter((_) => isEqual(_.date.toDate(), date)).some((_) => _.type === 'work');
    }).length;
  const remoteWorkCount = () =>
    Object.values(groupBy(timecards, 'date')).filter((timecards) => timecards.some((_) => _.remoteWorking)).length;

  const overtime = calendar.reduce(
    (x, y) => x + getOvertime(startDay, y.date, organization, timecards, workStyle, prescribedWorkTime),
    0
  );
  const lateNight = calendar.reduce((x, y) => x + getLateNight(y.date, organization, timecards), 0);

  return (
    <tr>
      <td>{user.employeeNumber}</td>
      <td>
        <a href={`/organizations/${organization.id}/users/${user.id}?month=${formatDate(month, 'yyyy-MM')}`}>
          {user.displayName}
        </a>
        <UnpaidLeaveAlert user={user} startDay={startDay} assignedHolidays={assignedHolidays} />
      </td>
      {isMobile ? (
        <>
          <td>
            <div className="row">
              <span className="col">勤務率</span>
              <span id={`work-time-per-${user.id}`} className="col">
                {numeral(totalWorkTime(startDay, organization, timecards, assignedHolidays) / prescribedWorkTime).format(
                  '0.00'
                )}{' '}
                ({formatTime(totalWorkTime(startDay, organization, timecards, assignedHolidays))} /{' '}
                {formatTime(prescribedWorkTime)})
              </span>
              <Tooltip
                isOpen={workTimePerTooltipOpen}
                target={`work-time-per-${user.id}`}
                toggle={toggleWorkTimePerTooltipOpen}
              >
                勤務時間＋特別休暇以外の休暇 / 所定労働時間
              </Tooltip>
            </div>
            <div className="row">
              <span className="col">就業形態</span>
              <span className="col"> {workStyle?.workStyleText}</span>
            </div>
            <div className="row">
              <span className="col">勤務</span>
              <span className="col"> {formatTime(actualWorkTime(timecards))}</span>

              <LunchOvertimeAlert user={user} organization={organization} timecards={timecards} />
              <WorkHourIntervalAlert user={user} timecards={timecards} organization={organization} startDay={startDay} />
            </div>
            <div className="row">
              <span className="col">出勤日数</span>
              <span className="col"> {workCount()}</span>
            </div>
            <div className="row">
              <span className="col">休日出勤数</span>
              <span className="col"> {holidayWorkCount()}</span>

              <HolidayAlert user={user} timecards={timecards} calendar={calendar} />
            </div>
            <div className="row">
              <span className="col">法定休日出勤</span>
              <span className="col"> {formatTime(legalHolidayWorkTime(calendar, timecards))}</span>
            </div>
            {!organization.excessStatutoryHolidayIsWork && (
              <div className="row">
                <span className="col">所定休日出勤</span>
                <span className="col"> {formatTime(regularHolidayWorkTime(calendar, timecards))}</span>
              </div>
            )}
            <div className="row">
              <span className="col">残業</span>
              <span className="col"> {formatTime(overtime)}</span>
            </div>
            <div className="row">
              <span className="col">深夜勤務</span>
              <span className="col"> {formatTime(lateNight)}</span>
            </div>
            <div className="row">
              <span className="col">
                代休
                <br />
                <small>(消化/付与)</small>
              </span>
              <span className="col">
                {formatDay(usage.compensatoryHoliday.assign)} / {formatDay(usage.compensatoryHoliday.grant)}
              </span>
            </div>
            <div className="row">
              <span className="col">
                有休(時間)
                <br />
                <small>(消化/付与)</small>
              </span>
              <span className="col">
                {formatTime(usage.paidHolidayTime.assign)} / {formatTime(usage.paidHolidayTime.grant)}
              </span>
            </div>
            <div className="row">
              <span className="col">
                有休(日)
                <br />
                <small>(消化/付与)</small>
              </span>
              <span className="col">
                {formatDay(usage.paidHolidayDate.assign)} / {formatDay(usage.paidHolidayDate.grant)}
              </span>
            </div>
            <div className="row">
              <span className="col">
                特別休暇
                <br />
                <small>(消化/付与)</small>
              </span>
              <span className="col">
                {formatDay(usage.specialHoliday.assign)} / {formatDay(usage.specialHoliday.grant)}
              </span>
            </div>
            <div className="row">
              <span className="col">遅刻/早退</span>
              <span className="col"> {formatTime(getLateOrEarly(startDay, assignedHolidays))}</span>
            </div>
            <div className="row">
              <span className="col">在宅勤務</span>
              <span className="col"> {remoteWorkCount()}</span>
            </div>
          </td>
        </>
      ) : (
        <>
          <td>
            <span id={`work-time-per-${user.id}`}>
              {numeral(totalWorkTime(startDay, organization, timecards, assignedHolidays) / prescribedWorkTime).format('0.00')}{' '}
              ({formatTime(totalWorkTime(startDay, organization, timecards, assignedHolidays))} /{' '}
              {formatTime(prescribedWorkTime)})
            </span>
            <Tooltip isOpen={workTimePerTooltipOpen} target={`work-time-per-${user.id}`} toggle={toggleWorkTimePerTooltipOpen}>
              勤務時間＋特別休暇以外の休暇 / 所定労働時間
            </Tooltip>
          </td>
          <td>{workStyle?.workStyleText}</td>
          <td>
            {formatTime(actualWorkTime(timecards))}
            <LunchOvertimeAlert user={user} timecards={timecards} organization={organization} />
            <WorkHourIntervalAlert user={user} timecards={timecards} organization={organization} startDay={startDay} />
          </td>
          <td>{workCount()}</td>
          <td>
            {holidayWorkCount()}
            <HolidayAlert user={user} timecards={timecards} calendar={calendar} />
          </td>
          <td>{formatTime(legalHolidayWorkTime(calendar, timecards))}</td>
          {!organization.excessStatutoryHolidayIsWork && <td>{formatTime(regularHolidayWorkTime(calendar, timecards))}</td>}
          <td>{formatTime(overtime)}</td>
          <td>{formatTime(lateNight)}</td>
          <td>
            {formatDay(usage.compensatoryHoliday.assign)} / {formatDay(usage.compensatoryHoliday.grant)}
          </td>
          <td>
            {formatTime(usage.paidHolidayTime.assign)} / {formatTime(usage.paidHolidayTime.grant)}
          </td>
          <td>
            {formatDay(usage.paidHolidayDate.assign)} / {formatDay(usage.paidHolidayDate.grant)}
          </td>
          <td>
            {formatDay(usage.specialHoliday.assign)} / {formatDay(usage.specialHoliday.grant)}
          </td>
          <td>{formatTime(getLateOrEarly(startDay, assignedHolidays))}</td>
          <td>{remoteWorkCount()}</td>
        </>
      )}
    </tr>
  );
}
