import React from 'react';
import { useToggle } from 'react-use';
import { Button } from 'reactstrap';
import { parseISO } from 'date-fns';

import useCollectionSubscription from '../../../hooks/useCollectionSubscription';
import { paidHolidayTypes } from '../../../../shared/config';
import { minToHHmm } from '../../../../utils/time';
import HolidayGrantModal from '../../../modals/HolidayGrantModal';
import { GrantedHoliday, Leave } from '../../../../models';

export default function PeriodSettingRow(props) {
  const { organization, user, period, userRef } = props;
  const [showModal, toggle] = useToggle(false);
  const periodRange = organization.periodRange(period);
  const grantedHolidays = useCollectionSubscription(
    userRef
      .collection('grantedHolidays')
      .orderBy('date')
      .startAt(parseISO(`${periodRange.begin} 00:00+09:00`))
      .endAt(parseISO(`${periodRange.end} 23:59+09:00`)),
    [period]
  ).map((_) => new GrantedHoliday(_));
  const leaves = useCollectionSubscription(
    userRef.collection('leaves').where('period', '==', period), [period]
  ).map((_) => new Leave(_));

  const column = (type, format) => {
    const value = grantedHolidays.filter((_) => _.type === type).reduce((x, y) => x + y.value, 0) || '';
    const formattedValue = format === 'time' ? minToHHmm(value) : value;
    return (
      <td key={type} style={{ textAlign: 'center' }}>
        {formattedValue}
      </td>
    );
  };

  return (
    <tr>
      <td>{user.displayName}</td>
      {paidHolidayTypes.map(({ type, format }) => column(type, format))}
      <td>{leaves.length ? '✔︎' : ''}</td>
      <td>
        <Button color="primary" size="sm" onClick={toggle}>
          <i className="fas fa-edit"></i>
        </Button>
        <HolidayGrantModal
          {...props}
          periodRange={periodRange}
          items={grantedHolidays}
          leaves={leaves}
          isOpen={showModal}
          onClose={toggle}
        />
      </td>
    </tr>
  );
}
