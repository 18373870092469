import React, { useState } from 'react';
import { useToggle } from 'react-use';
import { Redirect } from 'react-router-dom';
import { Button, Form, FormGroup, Label, Input, Modal, ModalBody, ModalFooter, Alert } from 'reactstrap';
import UnAuthPage from '../hocs/UnAuthPage';
import { auth } from '../../firebase';
import { toast } from 'react-toastify';

const ERRORS = {
  'auth/invalid-email': 'メールアドレスの形式が不正です。',
  'auth/wrong-password': 'メールアドレスかパスワードが誤っています。',
  'auth/user-not-found': 'メールアドレスかパスワードが誤っています。',
};

export default UnAuthPage(function SignIn(props) {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [showResetModal, toggle] = useToggle();
  const [resetEmail, setResetEmail] = useState();

  const onSubmit = async (event) => {
    event.preventDefault();
    auth.signInWithEmailAndPassword(email, password).catch((error) => {
      const message = ERRORS[error.code] || '不明なエラーです。';
      setErrorMessage(message);
    });
  };

  const onResetPassword = async (event) => {
    event.preventDefault();
    await auth.sendPasswordResetEmail(resetEmail).then(() => {
      toast.success('パスワードリセットメールを送信しました。');
    }).catch(() => {
      toast.error('リセットメール送信に失敗しました。')
    });
  };

  return props.firebaseUser ? (
    <Redirect to="/" />
  ) : (
    <div>
      <Modal isOpen backdrop={false} keyboard={false} centered>
        <Form onSubmit={onSubmit}>
          <ModalBody>
            <FormGroup>
              <Label for="email">メールアドレス</Label>
              <Input type="email" id="email" onChange={(e) => setEmail(e.target.value)} />
            </FormGroup>
            <FormGroup>
              <Label for="password">パスワード</Label>
              <Input type="password" id="password" onChange={(e) => setPassword(e.target.value)} />
            </FormGroup>
            {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={toggle}>
              <small>パスワードを忘れた方はこちら</small>
            </Button>
            <Button color="primary" type="submit" onClick={onSubmit}>
              ログイン
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <Modal isOpen={showResetModal} toggle={toggle}>
        <Form onSubmit={onResetPassword}>
          <ModalBody>
            <FormGroup>
              <Label for="reset-email">メールアドレス</Label>
              <Input type="email" id="reset-email" onChange={(e) => setResetEmail(e.target.value)} />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="warning" type="submit" onClick={onResetPassword}>
              リセットメールを送信する
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
});
