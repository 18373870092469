import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';
import env from './env';

if (['production', 'staging'].includes(process.env.REACT_APP_ENV)) {
  Bugsnag.start({
    apiKey: env('BUGSNAG_API_KEY'),
    plugins: [new BugsnagPluginReact(React)],
    releaseStage: process.env.REACT_APP_ENV || 'development',
  });
}
