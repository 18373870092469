import React from 'react';
import { useToggle } from 'react-use';
import { Card, CardBody, Table, Button } from 'reactstrap';
import { toast } from 'react-toastify';

import Page from '../../hocs/Page';
import useCollectionSubscription from '../../hooks/useCollectionSubscription';
import PublicHolidaySettingModal from '../../modals/PublicHolidaySettingModal';
import EditButton from '../../EditButton';
import DeleteButton from '../../DeleteButton';
import { db } from '../../../firebase';
import { formatDate } from '../../../utils/time';

const organizationsRef = db.collection('organizations');

export default Page(function PublicHolidaySetting(props) {
  const { organizationId } = props;
  const [showsFormModal, toggleFormModal] = useToggle();
  const publicHolidaysRef = organizationsRef.doc(organizationId).collection('publicHolidays');
  const publicHolidays = useCollectionSubscription(publicHolidaysRef.orderBy('date')) || [];

  const onSubmitForm = async (values) => {
    try {
      await publicHolidaysRef.add({ ...values });
      toast.success('追加しました');
    } catch (e) {
      toast.error('失敗しました');
      console.error(e);
    }
    toggleFormModal();
  };

  return (
    <div className="container py-5 position-relative">
      <Card className="my-5">
        <CardBody>
          <div className="d-flex justify-content-center">
            <h4>祝日設定</h4>
          </div>
          <div className="d-flex justify-content-end py-3">
            <Button color="primary" onClick={toggleFormModal}>
              <i className="fas fa-plus mr-1"></i>登録
            </Button>
          </div>
          <Table>
            <thead>
              <tr>
                <th>名前</th>
                <th>日付</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {publicHolidays.map(({ id, ref, name, date }) => (
                <tr key={id}>
                  <td>{name}</td>
                  <td>{formatDate(date.toDate(), 'yyyy/MM/dd')}</td>
                  <td className="text-right text-nowrap">
                    <EditButton itemRef={ref} FormModal={PublicHolidaySettingModal} />
                    <DeleteButton itemRef={ref} className="ml-2" />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <PublicHolidaySettingModal isOpen={showsFormModal} onClickClose={toggleFormModal} onSubmit={onSubmitForm} />
    </div>
  );
});
