import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { SyncProvider } from './contexts/SyncContext';
import { auth } from '../firebase';
import useDevice from './hooks/useDevice';

export default function Root(props) {
  const [firebaseUser, setFirebaseUser] = useState();
  const { isMobile } = useDevice();
  // eslint-disable-next-line
  isMobile && import('../css/mobile.css');

  useEffect(() => {
    return auth.onAuthStateChanged((firebaseUser) => {
      setFirebaseUser(firebaseUser);
    });
  }, []);

  return (
    <SyncProvider>
      <BrowserRouter>{props.routes({ firebaseUser })}</BrowserRouter>
      <ToastContainer />
    </SyncProvider>
  );
}
