const { reject } = require('lodash');

exports.holidayTypes = [
  {
    label: '代休',
    type: 'compensatory-holiday',
    format: 'date',
  },
  {
    label: '有給休暇(時間)',
    type: 'paid-holiday-time',
    format: 'time',
    unit: 60,
  },
  {
    label: '有給休暇(日)',
    type: 'paid-holiday-date',
    format: 'date',
  },
  {
    label: '特別休暇',
    type: 'special-holiday',
    format: 'date',
    words: ['特別休暇'],
  },
  {
    label: '遅刻/早退',
    type: 'late-or-early',
    format: 'time',
    words: ['遅刻', '早退'],
  },
  {
    label: '無給休暇',
    type: 'unpaid-leave',
    format: 'time',
    words: ['無給休暇'],
  },
];

exports.paidHolidayTypes = reject(this.holidayTypes, ({ type }) => type === 'unpaid-leave');

exports.roleNames = {
  member: 'メンバー',
  admin: '管理者',
  sysadmin: 'システム管理者',
};

// 規定ランチ時間：1.0h
exports.DEFAULT_LUNCH_TIME = 60;
// 1.0h休憩が必要になる最低労働時間：6.0h
exports.REST_TIME_REQUIRED_THRESHOLD = 360;
