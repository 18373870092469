import { groupBy } from 'lodash';
import { Timecard } from '../../types';
import { DEFAULT_LUNCH_TIME } from '../shared/config';

const defaultLunchTime: number = DEFAULT_LUNCH_TIME;

export const hasLunchOvertimeAlert = (timecards: Timecard[], lunchTime = defaultLunchTime) => {
  // 既定時間（デフォルト60分）以上のランチを取得している場合アラート
  const values = Object.values(groupBy(timecards, 'date')).map((timecards) =>
    timecards
      .filter(({ type }) => type === 'lunch')
      .map(({ to, from }) => (to.seconds - from.seconds) / 60)
      .reduce((x, y) => x + y, 0)
  );
  return values.some((_) => _ > lunchTime);
};
