export class User {
  constructor({
    id,
    ref,
    email = "",
    role = "",
    displayName = "",
    organizationId = "",
    group = "",
    disabled = false,
    employeeNumber = null,
    useShift = false,
    workPerWeek = 1.0,
  }) {
    this.id = id;
    this.ref = ref;
    this.email = email;
    this.role = role;
    this.displayName = displayName;
    this.organizationId = organizationId;
    this.group = group;
    this.disabled = disabled;
    this.employeeNumber = employeeNumber;
    this.useShift = useShift;
    this.workPerWeek = workPerWeek;
  }

  toObject() {
    return {
      email: this.email,
      role: this.role,
      displayName: this.displayName,
      organizationId: this.organizationId,
      group: this.group,
      disabled: this.disabled,
      employeeNumber: this.employeeNumber,
      useShift: this.useShift,
      workPerWeek: this.workPerWeek,
    };
  }

  get isAdmin() {
    return this.role === 'admin';
  }
  get isSysAdmin() {
    return this.role === 'sysadmin';
  }
}
